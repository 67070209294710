.google-feedback {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    gap: 2rem;

    @media (max-width: em(850)) {
      flex-direction: column;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (max-width: em(850)) {
      align-items: center;
    }
  }

  &__logo {
    max-width: 20rem;
  }

  &__btn {
    border-radius: 3rem;
    box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
    font-weight: 700;
    text-transform: none;
  }
}
