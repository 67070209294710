.contacts {
  &__logo {
    display: flex;
    align-items: center;
    max-width: 25rem;
    gap: 1rem;

    img {
      max-width: 5rem;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;
    gap: 4rem;

    @media (max-width: em(970)) {
      flex-direction: column;
    }
  }

  &-info {
    &__contacts {
      a {
        display: flex;
        font-size: 1.8rem;
        color: var(--clr-default-900);
        gap: 1rem;

        &:not(:first-child) {
          margin-top: 1rem;
        }

        svg {
          width: 2rem;
          height: 2rem;
        }
      }
    }

    &__socials {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 3rem;
      gap: 1rem;

      @media (max-width: em(970)) {
        justify-content: flex-start;
      }

      a {
        display: flex;
        align-items: center;
        border-radius: 3rem;
        padding: 0.5rem 1rem;
        width: 14rem;
        box-shadow: 0rem 0rem 1rem 0.2rem rgba(230, 230, 230, 1);
        font-size: 1.3rem;
        color: var(--clr-default-900);
        gap: 0.7rem;

        img {
          max-width: 3rem;
        }
      }
    }

    &__text {
      margin-top: 10rem;
      font-size: 1.5rem;
      line-height: 150%;

      @media (max-width: em(970)) {
        margin-top: 3rem;
      }

      span {
        font-weight: 700;
      }
    }

    &__btn {
      margin-top: 2rem;
      border-radius: 3rem;
      padding: 0.7rem 1rem;
      width: 100%;
      max-width: 26rem;
      box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: fluid(1.3rem, 0.8rem);

    &__item {
      display: flex;
      flex-grow: 1;
      flex-basis: 34%;
      cursor: pointer;
      gap: fluid(1.5rem, 0.8rem);

      @media (max-width: em(510)) {
        flex-basis: 100%;
      }
    }

    &__ico {
      max-width: 4rem;
      max-height: 4rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__city {
      font-weight: 700;
      font-size: fluid(1.8rem, 1.6rem);
    }

    &__address {
      margin-top: 0.3rem;
      font-size: fluid(1.8rem, 1.6rem);
    }
  }
}
