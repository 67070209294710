.team {
  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 7rem;
    gap: fluid(6rem, 2rem);
  }

  &-item {
    display: flex;
    flex-grow: 1;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: fluid(1.2rem, 1rem);

    @media (max-width: em(875)) {
      flex-basis: 34%;
      text-align: center;
    }

    &::after {
      content: "";
      display: flex;
      order: -1;
      margin-inline: auto;
      width: 12rem;
      height: 0.2rem;
      background-color: var(--clr-primary-100);
    }

    &__photo {
      display: flex;
      order: -3;
      width: max-content;
      width: 100%;
      max-width: 25rem;
      aspect-ratio: 1/1.1;

      img {
        width: 100%;
        aspect-ratio: 1/1.1;
      }
    }

    &__name {
      order: -2;
      font-weight: 700;
      font-size: 1.8rem;
    }

    &__position {
      flex-grow: 1;
      font-size: 1.6rem;
    }
  }
}
