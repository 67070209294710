.result {
  &-block {
    display: flex;
    overflow-y: auto;
    margin-top: 5rem;
    padding-bottom: 2rem;

    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--clr-primary-100);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--clr-bg-100);
    }

    &__scroll {
      display: flex;
      overflow: hidden;
      border: 0.2rem solid var(--clr-primary-100);
      border-radius: 3rem;
      width: 100%;
      min-width: 97rem;
    }

    &__item {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 8rem;
      font-size: 1.4rem;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.2rem solid var(--clr-primary-100);
      }

      &.active {
        font-weight: 700;
        color: var(--clr-default-100);
        background-color: var(--clr-primary-100);
      }
    }
  }

  &-content {
    display: flex;
    margin-top: 3rem;

    &__item {
      display: flex;
      gap: 2rem;

      @media (max-width: em(850)) {
        flex-direction: column;
      }
    }

    &__title {
      margin-bottom: 2rem;
      font-weight: 700;
      font-size: 1.8rem;
    }

    &__text {
      padding: 3.5rem 2.79rem;
      min-width: 30rem;
      max-width: 30rem;
      line-height: 1.8rem;
      color: var(--clr-default-100);
      background-color: var(--clr-bg-200);

      @media (max-width: em(850)) {
        min-width: 100%;
        max-width: 100%;
      }

      p {
        margin-top: 1.7rem;
        font-size: 1.4rem;

        span {
          font-weight: 700;
          color: var(--clr-primary-100);
        }
      }
    }

    &__photo {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }

    &__img {
      display: flex;
      flex-grow: 1;
      flex-basis: 34%;
      justify-content: center;
      border-style: solid;
      border-width: 1rem;
      border-color: var(--clr-default-100);
      box-shadow: 0.5rem 0.5rem 2rem 0rem rgba(0, 0, 0, 0.3);
      aspect-ratio: 1.2/0.9;

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }
}
