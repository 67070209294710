// <МИКСИНЫ>==================================================================
@import "./settings/mixins";

// </МИКСИНЫ>=================================================================

// ФУНКЦИИ====================================================================
@import "./settings/functions";

// </ФУНКЦИИ>=================================================================

// <Подключаем шрифты>========================================================
@import "./settings/fonts";

// </Подключаем шрифты>=======================================================

// <ОБНУЛЕНИЕ>================================================================
@import "./settings/normalize";

// </ОБНУЛЕНИЕ>===============================================================

// <ПЕРЕМЕННЫЕ>===============================================================
$containerWidth: 120rem;

:root {
  // base
  --font-family: "CenturyGothic", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.4s;
  --radius-100: 1.5rem;

  // colors
  --clr-default-100: #fff;
  --clr-default-400: #313131;
  --clr-default-900: #000;
  --clr-primary-100: #ec2230;

  --clr-bg-100: #f0f0f0;
  --clr-bg-200: #323232;
}

// </ПЕРЕМЕННЫЕ>==============================================================

// <ОБОЛОЧКИ>================================================================
body {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  color: var(--clr-default-400);

  &.lock {
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    overscroll-behavior: none;
  }
}

* {
  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--clr-bg-100);
  }

  &::-webkit-scrollbar-track {
    background-color: var(--clr-default-400);
  }
}

button {
  border: none;
  cursor: pointer;
}

input {
  outline: none;
  outline-width: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;

  &::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgb(0 0 0 / 50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition), visibility var(--transition);
  }

  &[data-overlay="true"]::before {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition), visibility var(--transition);
  }
}

main {
  flex-grow: 1;
}

// </ОБОЛОЧКИ>===============================================================

// <СЕТКА>===================================================================
.container {
  position: relative;
  z-index: 1;
  margin-inline: auto;
  padding-inline: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

// Components - UI
@import "./ui";

// Components - UI
@import "./libs/intlTelInput";

// Components - UI
@import "./components/ui/menu";
@import "./components/ui/logo";
@import "./components/ui/burger";

// Components - UI

// Components - Common
@import "./components/common/header";
@import "./components/common/footer";
@import "./components/common/faq";
@import "./components/common/modal";

// Components - Common

// Components - Pages
@import "./components/pages/index/hero";
@import "./components/pages/services/services";
@import "./components/pages/steps/steps";
@import "./components/pages/cost/cost";
@import "./components/pages/team/team";
@import "./components/pages/expertise/expertise";
@import "./components/pages/certificates/certificates";
@import "./components/pages/contacts/contacts";
@import "./components/pages/form/form";
@import "./components/pages/feedback/feedback";
@import "./components/pages/why/why";
@import "./components/pages/result/result";
@import "./components/pages/victim/victim";
@import "./components/pages/feedback/google-feedback";
@import "./components/pages/help/help";
@import "./components/pages/about/about";
@import "./components/pages/caces/caces";
@import "./components/pages/protected/protected";

// Components - Pages

// </СЕТКА>==================================================================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================

// <ПОДКЛЮЧЕНИЕ ФАЙЛОВ СТИЛЕЙ СТРАНИЦ И ЭЛЕМЕНТОВ>===========================
