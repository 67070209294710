.form {
  background-color: var(--clr-bg-200);

  &-military {
    position: relative;
    background-image: url("../img/military-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      transform: translate(-50%, -50%);
    }

    .form__wrapper {
      position: relative;
      z-index: 1;
    }
  }

  .title-main {
    color: var(--clr-default-100);
  }

  &__block {
    display: flex;
    justify-content: space-between;
    margin-top: fluid(5rem, 3rem);

    @media (max-width: em(920)) {
      flex-direction: column;
      justify-content: center;
      gap: 2rem;
    }
  }

  &__phone {
    span {
      font-weight: 700;
      font-size: 2.2rem;
      color: var(--clr-default-100);
    }

    a {
      display: flex;
      margin-top: 5rem;
      font-size: 2rem;
      color: var(--clr-default-100);
      gap: 1rem;

      &.form__phone-mt {
        margin-top: 1rem;
      }
    }
  }

  &__item {
    max-width: 43rem;

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 2rem;
      gap: 2rem;

      input {
        border-bottom-style: solid;
        border-bottom-width: 0.1rem;
        border-bottom-color: var(--clr-primary-100);
        padding: 1.5rem 1rem;
        width: 100%;
        max-width: 45rem;
        color: var(--clr-default-100);
        background-color: transparent;
      }

      .btn-primary {
        border-radius: 3rem;
        width: 100%;
        max-width: 45rem;
        font-weight: 700;
        font-size: fluid(2rem, 1.3rem);
        text-transform: uppercase;
      }
    }
  }

  &__caption {
    font-weight: 700;
    font-size: 2rem;
    color: var(--clr-default-100);
  }

  &__text {
    margin-top: 3rem;
    font-size: 1.2rem;
    color: #bdbdbd;
  }

  &-example {
    .form__caption {
      margin-bottom: 8rem;
      font-weight: 400;
      font-size: fluid(1.8rem, 1.6rem);

      @media (max-width: em(920)) {
        margin-bottom: 2rem;
      }
    }

    .btn-primary {
      font-size: 1.6rem !important;
    }
  }

  &__example {
    max-width: 56rem;
    transform: translateX(fluid(-0rem, -7rem));

    @media (max-width: em(480)) {
      min-width: fluid(58rem, 40rem);
      transform: translateX(fluid(-0rem, -8rem));
    }
  }
}
