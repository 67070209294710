.victim {
  background-color: var(--clr-bg-100);

  &__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 6rem;
    gap: 2rem;
  }

  &__item {
    display: flex;
    flex-basis: 21%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    height: fluid(15rem, 10rem);
    box-shadow: 0.2rem 0.2rem 2rem 0rem rgba(0, 0, 0, 0.3);
    background-color: var(--clr-default-100);
    transition: transform var(--transition);

    @media (max-width: em(780)) {
      flex-grow: 1;
      flex-basis: 34%;
    }

    &_hover:hover {
      transform: translateY(-2rem);

      & > * {
        transform: translateY(2rem);
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: transform var(--transition);
      gap: 1rem;
    }

    img {
      width: 3rem;
    }

    p {
      font-weight: 400;
    }
  }

  &-license {
    background-color: var(--clr-default-100);

    .victim__item {
      justify-content: start;
      height: auto;
      min-height: 20rem;
      font-weight: 700;
      font-size: 1.8rem;
      text-align: center;
    }
  }
}
