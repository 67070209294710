.help {
  .title-main {
    text-align: center;

    &::after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__subtitle {
    margin-top: 3rem;
    font-size: fluid(2.2rem, 1.6rem);
    text-align: center;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    gap: fluid(5rem, 2rem);
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 34%;
    gap: 2rem;

    @media (max-width: em(940)) {
      flex-basis: 100%;
    }
  }

  &__text {
    max-width: 80%;
    font-weight: 700;
    font-size: fluid(2.2rem, 1.6rem);
  }

  &__ico {
    width: 7rem;

    @media (max-width: em(600)) {
      width: 5rem;
    }
  }

  &__btn {
    margin-top: 4rem;
    margin-inline: auto;
    border-radius: 3rem;
    padding: 1.5rem 3rem;
    font-weight: 700;
    font-size: fluid(2rem, 1.6rem);
    text-align: center;

    @media (max-width: em(580)) {
      width: 100%;
    }
  }
}
