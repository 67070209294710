.steps {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: fluid(3rem, 1.5rem);
  }

  &__item {
    flex-grow: 11;
    flex-basis: 26%;

    @media (max-width: em(900)) {
      flex-basis: 34%;

      @media (max-width: em(630)) {
        flex-basis: 100%;
      }
    }
  }

  &__num {
    display: flex;
    align-items: flex-end;
    font-weight: 700;
    font-size: 5rem;
    gap: 0.5rem;

    &::after {
      content: "";
      display: flex;
      margin-bottom: 0.9rem;
      border-radius: 100%;
      width: 0.8rem;
      height: 0.8rem;
      background-color: var(--clr-primary-100);
    }
  }

  &__title {
    margin-top: 1rem;
    font-weight: 700;
    font-size: 2rem;
  }

  &__text {
    margin-top: 1rem;
    max-width: 90%;
    font-size: 1.6rem;
    line-height: 2.1rem;
  }
}
