.logo {
  a {
    display: flex;
    align-items: center;
    gap: 1.8rem;
  }

  img {
    display: flex;
    width: 3.6rem;
    height: 5.4rem;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  &__text {
    font-size: 1.3rem;
    color: var(--clr-default-100);

    @media (max-width: em(535)) {
      display: none;
    }
  }
}
