@charset "UTF-8";

/* stylelint-disable */

/* stylelint-disable */

/* stylelint-disable scss/operator-no-unspaced */

/* stylelint-disable scss/dollar-variable-colon-newline-after */

@font-face {
  font-family: "CenturyGothic";
  src: url("../fonts/CenturyGothic-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "CenturyGothic";
  src: url("../fonts/CenturyGothic.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

html {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  text-decoration: none;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

button {
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
}

ul,
ol {
  list-style: none;
}

input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.ios .lock {
  position: relative;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:root {
  --font-family: "CenturyGothic", sans-serif;
  --font-weight: 400;
  --content-width: 117rem;
  --container-offset: 1.5rem;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --transition: 0.4s;
  --radius-100: 1.5rem;
  --clr-default-100: #fff;
  --clr-default-400: #313131;
  --clr-default-900: #000;
  --clr-primary-100: #ec2230;
  --clr-bg-100: #f0f0f0;
  --clr-bg-200: #323232;
}

body {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  color: var(--clr-default-400);
}

body.lock {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

*::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.7rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--clr-bg-100);
}

*::-webkit-scrollbar-track {
  background-color: var(--clr-default-400);
}

button {
  border: none;
  cursor: pointer;
}

input {
  outline: none;
  outline-width: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  min-width: 32rem;
  min-height: 100%;
}

.wrapper::before {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition), visibility var(--transition);
}

.wrapper[data-overlay=true]::before {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition), visibility var(--transition);
}

main {
  flex-grow: 1;
}

.container {
  position: relative;
  z-index: 1;
  margin-inline: auto;
  padding-inline: var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.btn-primary {
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-primary-100);
  border-radius: 3.2rem;
  padding: 1.5rem 2.3rem;
  width: max-content;
  box-shadow: 0 0 0 0 transparent;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  transition: border-color var(--transition), background-color var(--transition), color var(--transition), box-shadow var(--transition);
}

.btn-primary:hover {
  background-color: #bd131e;
}

.btn-primary:focus {
  box-shadow: 0 0 1rem 0.1rem rgba(236, 34, 48, 0.68);
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
}

.title-main {
  position: relative;
  font-weight: 700;
  font-size: clamp(3rem, 2.5636363636rem + 1.3636363636vw, 4.2rem);
  color: #313131;
}

.title-main::after {
  content: "";
  position: absolute;
  left: 0;
  top: 110%;
  display: flex;
  width: 30rem;
  height: 0.3rem;
  background-color: var(--clr-primary-100);
}

.section {
  position: relative;
  padding-block: clamp(4rem, 2.7272727273rem + 3.9772727273vw, 7.5rem);
}

.dots-bg {
  position: absolute;
  width: 41rem;
  height: 35rem;
  opacity: 0.7;
}

.dots-bg_result {
  right: 0;
  bottom: 10rem;
  transform: rotate(180deg);
}

.dots-bg_why {
  right: 10rem;
  bottom: 50%;
  transform: rotate(180deg);
}

.dots-bg_why path {
  fill: var(--clr-default-100);
}

.dots-bg_services-1 {
  right: 0;
  top: 10rem;
  transform: rotate(90deg);
}

.dots-bg_services-2 {
  left: 0;
  bottom: 4rem;
}

.dots-bg_form-example {
  left: 0;
  bottom: 5rem;
}

.dots-bg_form-example path {
  fill: var(--clr-default-100);
}

.dots-bg_steps-1 {
  left: 0;
  top: 5rem;
}

.dots-bg_steps-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.dots-bg_cost-1 {
  left: 0;
  top: 5rem;
  transform: rotate(180deg);
}

.dots-bg_cost-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.dots-bg_team-1 {
  left: 0;
  top: 5rem;
  transform: rotate(180deg);
}

.dots-bg_team-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.dots-bg_certificates-1 {
  left: 5rem;
  bottom: 10rem;
  transform: rotate(-90deg);
}

.dots-bg_certificates-2 {
  right: 5rem;
  top: 10rem;
  transform: rotate(90deg);
}

.dots-bg_form {
  right: 50%;
  top: 50%;
  transform: translate(0, -50%) rotate(180deg);
}

.dots-bg_form path {
  fill: var(--clr-default-100);
}

.dots-bg_expertise-1 {
  left: 0;
  top: 5rem;
}

.dots-bg_expertise-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.dots-bg_contacts-1 {
  left: 0;
  bottom: 5rem;
}

.dots-bg_contacts-2 {
  right: 0;
  top: 10rem;
  transform: rotate(90deg);
}

.dots-bg_victim {
  right: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.dots-bg_team-1 {
  left: 0;
  top: 5rem;
  transform: rotate(180deg);
}

.dots-bg_team-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.dots-bg_help {
  left: 5rem;
  top: 0;
}

.dots-bg_about {
  left: 50%;
  top: 0;
  transform: rotate(180deg);
}

.dots-bg_about path {
  fill: var(--clr-default-100);
}

.dots-bg_caces-1 {
  left: 0;
  top: 5rem;
  transform: rotate(180deg);
}

.dots-bg_caces-2 {
  right: 0;
  bottom: 5rem;
  transform: rotate(180deg);
}

.iti {
  width: 100%;
}

#phone {
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--clr-primary-100);
  padding-block: 1.6rem 1.4rem;
  width: 100%;
  font-size: 1.6rem;
}

#phone1,
#phone2,
#phone3,
#phone4,
#phone5,
#phone6,
#phone7,
#phone8,
#phone9,
#phone12 {
  border-bottom-style: solid;
  border-bottom-width: 0.2rem;
  border-bottom-color: var(--clr-primary-100);
  padding-block: 1.6rem 1.4rem;
  width: 100%;
  font-size: 1.6rem;
}

.form .iti__selected-dial-code {
  font-size: 1.6rem;
  color: #fff;
}

.iti__selected-dial-code {
  font-weight: 400;
}

#btn {
  display: none;
}

#valid-msg,
#error-msg {
  display: none;
}

:root {
  --iti-hover-color: rgba(0, 0, 0, 0.05);
  --iti-border-color: #ccc;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: white;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 12px;
  --iti-flag-width: 16px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-path-flags-1x: url("../img/flags.webp");
  --iti-path-flags-2x: url("../img/flags@2x.webp");
  --iti-path-globe-1x: url("../img/globe.webp");
  --iti-path-globe-2x: url("../img/globe@2x.webp");
  --iti-flag-sprite-width: 3904px;
  --iti-flag-sprite-height: 12px;
  --iti-mobile-popup-margin: 30px;
}

.iti {
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti__a11y-text {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.iti input.iti__tel-input,
.iti input.iti__tel-input[type=tel],
.iti input.iti__tel-input[type=text] {
  position: relative;
  z-index: 0;
  margin: 0 !important;
}

.iti__country-container {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: var(--iti-border-width);
}

.iti__selected-country {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 0;
  height: 100%;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  color: inherit;
  background: 0 0;
}

.iti__selected-country-primary {
  display: flex;
  align-items: center;
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
  height: 100%;
}

.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
  border-right: var(--iti-triangle-border) solid transparent;
  border-left: var(--iti-triangle-border) solid transparent;
  width: 0;
  height: 0;
}

[dir=rtl] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}

.iti__dropdown-content {
  border-radius: 3px;
  background-color: var(--iti-dropdown-bg);
}

.iti--inline-dropdown .iti__dropdown-content {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.iti__search-input {
  border-width: 0;
  border-radius: 3px;
  width: 100%;
}

.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}

.iti__country-list {
  list-style: none;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch;
}

.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}

.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}

.iti__country {
  display: flex;
  align-items: center;
  outline: 0;
  padding: 8px var(--iti-spacer-horizontal);
}

.iti__dial-code {
  color: var(--iti-dialcode-color);
}

.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}

.iti__country-list .iti__flag,
.iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}

[dir=rtl] .iti__country-list .iti__flag,
[dir=rtl] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button {
  cursor: pointer;
}

.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
  background-color: var(--iti-hover-color);
}

.iti .iti__selected-dial-code {
  margin-left: 4px;
}

[dir=rtl] .iti .iti__selected-dial-code {
  margin-right: 4px;
  margin-left: 0;
}

.iti--container {
  position: fixed;
  left: -1000px;
  top: -1000px;
  z-index: 1060;
  padding: var(--iti-border-width);
}

.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--iti-mobile-popup-margin);
  background-color: rgba(0, 0, 0, 0.5);
}

.iti--fullscreen-popup .iti__dropdown-content {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.iti--fullscreen-popup .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 100px;
  border-radius: 1px;
  width: var(--iti-flag-width);
  height: var(--iti-flag-height);
  box-shadow: 0 0 1px 0 #888;
  background-image: var(--iti-path-flags-1x);
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
  background-repeat: no-repeat;
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -16px;
}

.iti__ae {
  --iti-flag-offset: -32px;
}

.iti__af {
  --iti-flag-offset: -48px;
}

.iti__ag {
  --iti-flag-offset: -64px;
}

.iti__ai {
  --iti-flag-offset: -80px;
}

.iti__al {
  --iti-flag-offset: -96px;
}

.iti__am {
  --iti-flag-offset: -112px;
}

.iti__ao {
  --iti-flag-offset: -128px;
}

.iti__ar {
  --iti-flag-offset: -144px;
}

.iti__as {
  --iti-flag-offset: -160px;
}

.iti__at {
  --iti-flag-offset: -176px;
}

.iti__au {
  --iti-flag-offset: -192px;
}

.iti__aw {
  --iti-flag-offset: -208px;
}

.iti__ax {
  --iti-flag-offset: -224px;
}

.iti__az {
  --iti-flag-offset: -240px;
}

.iti__ba {
  --iti-flag-offset: -256px;
}

.iti__bb {
  --iti-flag-offset: -272px;
}

.iti__bd {
  --iti-flag-offset: -288px;
}

.iti__be {
  --iti-flag-offset: -304px;
}

.iti__bf {
  --iti-flag-offset: -320px;
}

.iti__bg {
  --iti-flag-offset: -336px;
}

.iti__bh {
  --iti-flag-offset: -352px;
}

.iti__bi {
  --iti-flag-offset: -368px;
}

.iti__bj {
  --iti-flag-offset: -384px;
}

.iti__bl {
  --iti-flag-offset: -400px;
}

.iti__bm {
  --iti-flag-offset: -416px;
}

.iti__bn {
  --iti-flag-offset: -432px;
}

.iti__bo {
  --iti-flag-offset: -448px;
}

.iti__bq {
  --iti-flag-offset: -464px;
}

.iti__br {
  --iti-flag-offset: -480px;
}

.iti__bs {
  --iti-flag-offset: -496px;
}

.iti__bt {
  --iti-flag-offset: -512px;
}

.iti__bw {
  --iti-flag-offset: -528px;
}

.iti__by {
  --iti-flag-offset: -544px;
}

.iti__bz {
  --iti-flag-offset: -560px;
}

.iti__ca {
  --iti-flag-offset: -576px;
}

.iti__cc {
  --iti-flag-offset: -592px;
}

.iti__cd {
  --iti-flag-offset: -608px;
}

.iti__cf {
  --iti-flag-offset: -624px;
}

.iti__cg {
  --iti-flag-offset: -640px;
}

.iti__ch {
  --iti-flag-offset: -656px;
}

.iti__ci {
  --iti-flag-offset: -672px;
}

.iti__ck {
  --iti-flag-offset: -688px;
}

.iti__cl {
  --iti-flag-offset: -704px;
}

.iti__cm {
  --iti-flag-offset: -720px;
}

.iti__cn {
  --iti-flag-offset: -736px;
}

.iti__co {
  --iti-flag-offset: -752px;
}

.iti__cr {
  --iti-flag-offset: -768px;
}

.iti__cu {
  --iti-flag-offset: -784px;
}

.iti__cv {
  --iti-flag-offset: -800px;
}

.iti__cw {
  --iti-flag-offset: -816px;
}

.iti__cx {
  --iti-flag-offset: -832px;
}

.iti__cy {
  --iti-flag-offset: -848px;
}

.iti__cz {
  --iti-flag-offset: -864px;
}

.iti__de {
  --iti-flag-offset: -880px;
}

.iti__dj {
  --iti-flag-offset: -896px;
}

.iti__dk {
  --iti-flag-offset: -912px;
}

.iti__dm {
  --iti-flag-offset: -928px;
}

.iti__do {
  --iti-flag-offset: -944px;
}

.iti__dz {
  --iti-flag-offset: -960px;
}

.iti__ec {
  --iti-flag-offset: -976px;
}

.iti__ee {
  --iti-flag-offset: -992px;
}

.iti__eg {
  --iti-flag-offset: -1008px;
}

.iti__eh {
  --iti-flag-offset: -1024px;
}

.iti__er {
  --iti-flag-offset: -1040px;
}

.iti__es {
  --iti-flag-offset: -1056px;
}

.iti__et {
  --iti-flag-offset: -1072px;
}

.iti__fi {
  --iti-flag-offset: -1088px;
}

.iti__fj {
  --iti-flag-offset: -1104px;
}

.iti__fk {
  --iti-flag-offset: -1120px;
}

.iti__fm {
  --iti-flag-offset: -1136px;
}

.iti__fo {
  --iti-flag-offset: -1152px;
}

.iti__fr {
  --iti-flag-offset: -1168px;
}

.iti__ga {
  --iti-flag-offset: -1184px;
}

.iti__gb {
  --iti-flag-offset: -1200px;
}

.iti__gd {
  --iti-flag-offset: -1216px;
}

.iti__ge {
  --iti-flag-offset: -1232px;
}

.iti__gf {
  --iti-flag-offset: -1248px;
}

.iti__gg {
  --iti-flag-offset: -1264px;
}

.iti__gh {
  --iti-flag-offset: -1280px;
}

.iti__gi {
  --iti-flag-offset: -1296px;
}

.iti__gl {
  --iti-flag-offset: -1312px;
}

.iti__gm {
  --iti-flag-offset: -1328px;
}

.iti__gn {
  --iti-flag-offset: -1344px;
}

.iti__gp {
  --iti-flag-offset: -1360px;
}

.iti__gq {
  --iti-flag-offset: -1376px;
}

.iti__gr {
  --iti-flag-offset: -1392px;
}

.iti__gt {
  --iti-flag-offset: -1408px;
}

.iti__gu {
  --iti-flag-offset: -1424px;
}

.iti__gw {
  --iti-flag-offset: -1440px;
}

.iti__gy {
  --iti-flag-offset: -1456px;
}

.iti__hk {
  --iti-flag-offset: -1472px;
}

.iti__hn {
  --iti-flag-offset: -1488px;
}

.iti__hr {
  --iti-flag-offset: -1504px;
}

.iti__ht {
  --iti-flag-offset: -1520px;
}

.iti__hu {
  --iti-flag-offset: -1536px;
}

.iti__id {
  --iti-flag-offset: -1552px;
}

.iti__ie {
  --iti-flag-offset: -1568px;
}

.iti__il {
  --iti-flag-offset: -1584px;
}

.iti__im {
  --iti-flag-offset: -1600px;
}

.iti__in {
  --iti-flag-offset: -1616px;
}

.iti__io {
  --iti-flag-offset: -1632px;
}

.iti__iq {
  --iti-flag-offset: -1648px;
}

.iti__ir {
  --iti-flag-offset: -1664px;
}

.iti__is {
  --iti-flag-offset: -1680px;
}

.iti__it {
  --iti-flag-offset: -1696px;
}

.iti__je {
  --iti-flag-offset: -1712px;
}

.iti__jm {
  --iti-flag-offset: -1728px;
}

.iti__jo {
  --iti-flag-offset: -1744px;
}

.iti__jp {
  --iti-flag-offset: -1760px;
}

.iti__ke {
  --iti-flag-offset: -1776px;
}

.iti__kg {
  --iti-flag-offset: -1792px;
}

.iti__kh {
  --iti-flag-offset: -1808px;
}

.iti__ki {
  --iti-flag-offset: -1824px;
}

.iti__km {
  --iti-flag-offset: -1840px;
}

.iti__kn {
  --iti-flag-offset: -1856px;
}

.iti__kp {
  --iti-flag-offset: -1872px;
}

.iti__kr {
  --iti-flag-offset: -1888px;
}

.iti__kw {
  --iti-flag-offset: -1904px;
}

.iti__ky {
  --iti-flag-offset: -1920px;
}

.iti__kz {
  --iti-flag-offset: -1936px;
}

.iti__la {
  --iti-flag-offset: -1952px;
}

.iti__lb {
  --iti-flag-offset: -1968px;
}

.iti__lc {
  --iti-flag-offset: -1984px;
}

.iti__li {
  --iti-flag-offset: -2000px;
}

.iti__lk {
  --iti-flag-offset: -2016px;
}

.iti__lr {
  --iti-flag-offset: -2032px;
}

.iti__ls {
  --iti-flag-offset: -2048px;
}

.iti__lt {
  --iti-flag-offset: -2064px;
}

.iti__lu {
  --iti-flag-offset: -2080px;
}

.iti__lv {
  --iti-flag-offset: -2096px;
}

.iti__ly {
  --iti-flag-offset: -2112px;
}

.iti__ma {
  --iti-flag-offset: -2128px;
}

.iti__mc {
  --iti-flag-offset: -2144px;
}

.iti__md {
  --iti-flag-offset: -2160px;
}

.iti__me {
  --iti-flag-offset: -2176px;
}

.iti__mf {
  --iti-flag-offset: -2192px;
}

.iti__mg {
  --iti-flag-offset: -2208px;
}

.iti__mh {
  --iti-flag-offset: -2224px;
}

.iti__mk {
  --iti-flag-offset: -2240px;
}

.iti__ml {
  --iti-flag-offset: -2256px;
}

.iti__mm {
  --iti-flag-offset: -2272px;
}

.iti__mn {
  --iti-flag-offset: -2288px;
}

.iti__mo {
  --iti-flag-offset: -2304px;
}

.iti__mp {
  --iti-flag-offset: -2320px;
}

.iti__mq {
  --iti-flag-offset: -2336px;
}

.iti__mr {
  --iti-flag-offset: -2352px;
}

.iti__ms {
  --iti-flag-offset: -2368px;
}

.iti__mt {
  --iti-flag-offset: -2384px;
}

.iti__mu {
  --iti-flag-offset: -2400px;
}

.iti__mv {
  --iti-flag-offset: -2416px;
}

.iti__mw {
  --iti-flag-offset: -2432px;
}

.iti__mx {
  --iti-flag-offset: -2448px;
}

.iti__my {
  --iti-flag-offset: -2464px;
}

.iti__mz {
  --iti-flag-offset: -2480px;
}

.iti__na {
  --iti-flag-offset: -2496px;
}

.iti__nc {
  --iti-flag-offset: -2512px;
}

.iti__ne {
  --iti-flag-offset: -2528px;
}

.iti__nf {
  --iti-flag-offset: -2544px;
}

.iti__ng {
  --iti-flag-offset: -2560px;
}

.iti__ni {
  --iti-flag-offset: -2576px;
}

.iti__nl {
  --iti-flag-offset: -2592px;
}

.iti__no {
  --iti-flag-offset: -2608px;
}

.iti__np {
  --iti-flag-offset: -2624px;
}

.iti__nr {
  --iti-flag-offset: -2640px;
}

.iti__nu {
  --iti-flag-offset: -2656px;
}

.iti__nz {
  --iti-flag-offset: -2672px;
}

.iti__om {
  --iti-flag-offset: -2688px;
}

.iti__pa {
  --iti-flag-offset: -2704px;
}

.iti__pe {
  --iti-flag-offset: -2720px;
}

.iti__pf {
  --iti-flag-offset: -2736px;
}

.iti__pg {
  --iti-flag-offset: -2752px;
}

.iti__ph {
  --iti-flag-offset: -2768px;
}

.iti__pk {
  --iti-flag-offset: -2784px;
}

.iti__pl {
  --iti-flag-offset: -2800px;
}

.iti__pm {
  --iti-flag-offset: -2816px;
}

.iti__pr {
  --iti-flag-offset: -2832px;
}

.iti__ps {
  --iti-flag-offset: -2848px;
}

.iti__pt {
  --iti-flag-offset: -2864px;
}

.iti__pw {
  --iti-flag-offset: -2880px;
}

.iti__py {
  --iti-flag-offset: -2896px;
}

.iti__qa {
  --iti-flag-offset: -2912px;
}

.iti__re {
  --iti-flag-offset: -2928px;
}

.iti__ro {
  --iti-flag-offset: -2944px;
}

.iti__rs {
  --iti-flag-offset: -2960px;
}

.iti__ru {
  --iti-flag-offset: -2976px;
}

.iti__rw {
  --iti-flag-offset: -2992px;
}

.iti__sa {
  --iti-flag-offset: -3008px;
}

.iti__sb {
  --iti-flag-offset: -3024px;
}

.iti__sc {
  --iti-flag-offset: -3040px;
}

.iti__sd {
  --iti-flag-offset: -3056px;
}

.iti__se {
  --iti-flag-offset: -3072px;
}

.iti__sg {
  --iti-flag-offset: -3088px;
}

.iti__sh {
  --iti-flag-offset: -3104px;
}

.iti__si {
  --iti-flag-offset: -3120px;
}

.iti__sj {
  --iti-flag-offset: -3136px;
}

.iti__sk {
  --iti-flag-offset: -3152px;
}

.iti__sl {
  --iti-flag-offset: -3168px;
}

.iti__sm {
  --iti-flag-offset: -3184px;
}

.iti__sn {
  --iti-flag-offset: -3200px;
}

.iti__so {
  --iti-flag-offset: -3216px;
}

.iti__sr {
  --iti-flag-offset: -3232px;
}

.iti__ss {
  --iti-flag-offset: -3248px;
}

.iti__st {
  --iti-flag-offset: -3264px;
}

.iti__sv {
  --iti-flag-offset: -3280px;
}

.iti__sx {
  --iti-flag-offset: -3296px;
}

.iti__sy {
  --iti-flag-offset: -3312px;
}

.iti__sz {
  --iti-flag-offset: -3328px;
}

.iti__tc {
  --iti-flag-offset: -3344px;
}

.iti__td {
  --iti-flag-offset: -3360px;
}

.iti__tg {
  --iti-flag-offset: -3376px;
}

.iti__th {
  --iti-flag-offset: -3392px;
}

.iti__tj {
  --iti-flag-offset: -3408px;
}

.iti__tk {
  --iti-flag-offset: -3424px;
}

.iti__tl {
  --iti-flag-offset: -3440px;
}

.iti__tm {
  --iti-flag-offset: -3456px;
}

.iti__tn {
  --iti-flag-offset: -3472px;
}

.iti__to {
  --iti-flag-offset: -3488px;
}

.iti__tr {
  --iti-flag-offset: -3504px;
}

.iti__tt {
  --iti-flag-offset: -3520px;
}

.iti__tv {
  --iti-flag-offset: -3536px;
}

.iti__tw {
  --iti-flag-offset: -3552px;
}

.iti__tz {
  --iti-flag-offset: -3568px;
}

.iti__ua {
  --iti-flag-offset: -3584px;
}

.iti__ug {
  --iti-flag-offset: -3600px;
}

.iti__us {
  --iti-flag-offset: -3616px;
}

.iti__uy {
  --iti-flag-offset: -3632px;
}

.iti__uz {
  --iti-flag-offset: -3648px;
}

.iti__va {
  --iti-flag-offset: -3664px;
}

.iti__vc {
  --iti-flag-offset: -3680px;
}

.iti__ve {
  --iti-flag-offset: -3696px;
}

.iti__vg {
  --iti-flag-offset: -3712px;
}

.iti__vi {
  --iti-flag-offset: -3728px;
}

.iti__vn {
  --iti-flag-offset: -3744px;
}

.iti__vu {
  --iti-flag-offset: -3760px;
}

.iti__wf {
  --iti-flag-offset: -3776px;
}

.iti__ws {
  --iti-flag-offset: -3792px;
}

.iti__xk {
  --iti-flag-offset: -3808px;
}

.iti__ye {
  --iti-flag-offset: -3824px;
}

.iti__yt {
  --iti-flag-offset: -3840px;
}

.iti__za {
  --iti-flag-offset: -3856px;
}

.iti__zm {
  --iti-flag-offset: -3872px;
}

.iti__zw {
  --iti-flag-offset: -3888px;
}

.iti__globe {
  height: 19px;
  box-shadow: none;
  background-image: var(--iti-path-globe-1x);
  background-position: right;
  background-size: contain;
}

.menu {
  padding-inline: 1rem;
}

.menu__list {
  display: flex;
  align-items: center;
}

.menu__item {
  position: relative;
  padding-block: 2rem;
}

.menu__item:not(:last-child) {
  margin-right: 2.5rem;
}

.menu__item::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 0;
  height: 0.1rem;
  background-color: var(--clr-default-100);
  transform: translate(-50%, 1rem);
  transition: width var(--transition);
}

.menu__item:hover::after {
  width: 100%;
}

.menu__item_hover {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.menu__item_hover::before {
  content: "";
  order: 1;
  border-style: solid;
  border-width: 4px 3px 0 3px;
  border-color: var(--clr-default-100) transparent transparent transparent;
  transform: rotate(0deg);
  transition: transform var(--transition);
}

.menu__item_hover:hover::before {
  transform: rotate(-180deg);
}

.menu__item_hover:hover .menu-dropdown {
  display: block;
}

.menu__link {
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--clr-default-100);
}

.menu-dropdown {
  position: absolute;
  left: 50%;
  top: 100%;
  z-index: 2;
  display: none;
  border-radius: 1rem;
  padding: 1rem;
  width: 15rem;
  background-color: var(--clr-default-100);
  transform: translateX(-50%);
}

.menu-dropdown::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  border-style: solid;
  border-width: 0 1rem 1.3rem 1rem;
  border-color: transparent transparent var(--clr-default-100) transparent;
  transform: rotate(0deg) translate(-50%, -1rem);
}

.menu-dropdown li:not(:first-child) {
  margin-top: 1rem;
}

.menu-dropdown a {
  display: flex;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
  color: var(--clr-default-900);
}

.menu__btn {
  display: none;
}

.menu__tel {
  display: none;
  color: var(--clr-default-100);
}

.menu__address {
  display: none;
  margin-top: 4rem;
  color: var(--clr-default-100);
}

.menu-socials {
  display: none;
  justify-content: space-around;
  margin-top: 3rem;
  gap: 1rem;
}

.menu-socials a {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 5rem;
  font-size: 1.2rem;
  color: var(--clr-default-100);
  gap: 1rem;
}

.menu-socials a img {
  max-width: 80%;
}

.menu__btn-phone {
  display: none;
  justify-content: center;
  margin-top: auto;
  border-radius: 3rem;
  padding: 1.5rem 2.4rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #fff;
  background-color: var(--clr-primary-100);
}

.logo a {
  display: flex;
  align-items: center;
  gap: 1.8rem;
}

.logo img {
  display: flex;
  width: 3.6rem;
  height: 5.4rem;
}

.logo__wrap {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.logo__text {
  font-size: 1.3rem;
  color: var(--clr-default-100);
}

.burger {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  background-color: #323232;
}

.header .container {
  z-index: 20;
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.7rem;
}

.header-info__city {
  display: none;
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--clr-default-100);
}

.header-info__text {
  font-size: 1.4rem;
  text-align: right;
  color: #b0b0b0;
}

.header-info__tel {
  position: relative;
  font-weight: normal;
  color: var(--clr-default-100);
}

.header-info__tel::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 93%;
  display: flex;
  width: 0;
  height: 0.1rem;
  background-color: var(--clr-default-100);
  transform: translateX(-50%);
  transition: width var(--transition);
}

.header-info__tel:hover::after {
  width: 100%;
}

.header__burger {
  position: fixed;
  right: 1rem;
  top: 2rem;
  z-index: 22;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 4.11rem;
  height: 4.11rem;
  background-color: var(--clr-primary-100);
}

.header .btn-primary {
  border-radius: 2rem;
  padding: 0.8rem;
  width: 100%;
  max-width: clamp(5rem, -1.5454545455rem + 20.4545454545vw, 23rem);
  font-weight: 400;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.footer__wrapper {
  display: flex;
}

.faq {
  padding-top: 10rem;
}

.faq__list {
  margin-top: 4rem;
}

.faq__item {
  position: relative;
  background-color: var(--clr-bg-100);
}

.faq__item:not(:last-child) {
  margin-bottom: 0.3rem;
  transition: margin var(--transition);
}

.faq__item-title {
  position: relative;
  border-radius: var(--radius);
  padding: 2rem 10rem 2rem 4rem;
  width: 100%;
  font-weight: 700;
  font-size: clamp(1.6rem, 1.5272727273rem + 0.2272727273vw, 1.8rem);
  line-height: 135%;
  background-color: var(--clr-bg-100);
  cursor: pointer;
}

.faq__item-title::after {
  content: "";
  position: absolute;
  right: 2rem;
  top: 2rem;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  font-size: 5rem;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path fill="white" stroke="white" stroke-width="6" d="M220,128a4.0002,4.0002,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4.0002,4.0002,0,0,1,220,128Z"/></svg>');
  background-color: var(--clr-primary-100);
  transition: background-color var(--transition), transform var(--transition);
}

.faq__item-body {
  overflow: hidden;
  max-height: 0;
  box-sizing: content-box;
  line-height: 160%;
  transition: max-height var(--transition);
}

.faq__item-body > div {
  margin-top: 0;
  padding: 0 4rem 2rem;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(1.4rem, 1.2545454545rem + 0.4545454545vw, 1.8rem);
  font-size: 1.6rem;
  line-height: 155%;
  letter-spacing: 0.036rem;
}

.faq__item-body > div ul {
  margin-top: 1rem;
  padding-left: 2rem;
}

.faq__item-body > div li {
  position: relative;
}

.faq__item-body > div li span {
  font-weight: 700;
}

.faq__item-body > div li::before {
  content: "";
  position: absolute;
  left: -1.8rem;
  top: 0rem;
  margin-top: 1rem;
  border-radius: 100%;
  min-width: 0.5rem;
  min-height: 0.5rem;
  max-width: 0.5rem;
  max-height: 0.5rem;
  background-color: var(--clr-default-900);
}

.faq__item-body > div li,
.faq__item-body > div div {
  font-size: 1.6rem;
}

.faq__item-red,
.faq__item-bold {
  display: block;
  margin-top: 1rem;
  font-weight: 700;
}

.faq__item-red {
  color: var(--clr-primary-100);
}

.faq__item--active .faq__item-title::after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path fill="black" d="M220,128a4.0002,4.0002,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4.0002,4.0002,0,0,1,220,128Z"/></svg>');
  background-color: transparent;
  transform: rotate(-45deg);
}

/* Стили для модального окна */

.modal-call {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: none;
  /* Изначально скрыто */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-call__content {
  position: relative;
  padding: 4rem 4.5rem;
  width: 100%;
  max-width: 66rem;
  background-color: var(--clr-default-100);
}

.modal-call__caption {
  font-weight: 700;
  font-size: 2.8rem;
  text-align: center;
}

.modal-call__text {
  margin-top: 2rem;
  font-size: 1.8rem;
  text-align: center;
}

.modal-call__form {
  margin-top: 2rem;
}

.modal-call__form form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.modal-call__form form input {
  border-bottom-style: solid;
  border-bottom-width: 0.2rem;
  border-bottom-color: var(--clr-primary-100);
  padding-block: 1.77rem;
  font-size: 1.4rem;
}

.modal-call__form form button {
  border-radius: 3rem;
  width: 100%;
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
}

/* Кнопка закрытия */

.modal-call__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;
}

.modal-call__close::after,
.modal-call__close::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background-color: var(--clr-default-900);
}

.modal-call__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal-call__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.hero {
  position: relative;
  padding-block: 2.2rem 5rem;
  background-image: url("../img/hero-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-life {
  background-image: url("../img/hero-bg-life.jpg");
}

.hero-life .hero__title {
  position: relative;
  padding-right: 3rem;
  max-width: 99rem;
  font-size: clamp(2rem, 1.4545454545rem + 1.7045454545vw, 3.5rem);
}

.hero-life .hero__title-svg {
  position: absolute;
  right: 0rem;
  top: 0;
  display: inline-block;
}

.hero-life .hero__title-svg:hover .hero__title-svg-text {
  display: block;
}

.hero-life .hero__title-svg-text {
  position: absolute;
  right: 0;
  top: 100%;
  display: none;
  border-radius: 1rem;
  padding: 1rem;
  width: 27rem;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: left;
  color: var(--clr-default-900);
  background-color: #fff;
}

.hero-life .hero__title-svg-text span {
  color: var(--clr-primary-100);
}

.hero-life .hero__title svg {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  background-color: var(--clr-primary-100);
}

.hero::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(28, 28, 28, 0.8), rgba(28, 28, 28, 0.8));
}

.hero__wrapper {
  position: relative;
  z-index: 1;
}

.hero__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero__city {
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-default-100);
  border-radius: 3rem;
  padding-block: 1.5rem;
  width: 30rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  color: var(--clr-default-100);
}

.hero__text {
  max-width: 27.3rem;
  font-size: clamp(1rem, 0.7818181818rem + 0.6818181818vw, 1.6rem);
  text-align: right;
  color: var(--clr-default-100);
}

.hero__title {
  margin-top: 1rem;
  margin-inline: auto;
  max-width: 68.5rem;
  font-size: clamp(2rem, 1.2727272727rem + 2.2727272727vw, 4rem);
  text-align: center;
  color: var(--clr-default-100);
}

.hero__subtitle {
  margin-top: 2rem;
  font-size: clamp(1.4rem, 1.1818181818rem + 0.6818181818vw, 2rem);
  text-align: center;
  color: var(--clr-default-100);
}

.hero__block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: clamp(2rem, 0.9090909091rem + 3.4090909091vw, 5rem);
  gap: 3rem;
}

.hero-info {
  flex-basis: 42%;
  background-color: var(--clr-default-100);
}

.hero-info__title {
  font-size: clamp(1.5rem, 1.3181818182rem + 0.5681818182vw, 2rem);
  text-align: center;
}

.hero-info__title span {
  text-transform: uppercase;
  color: var(--clr-primary-100);
}

.hero-info__caption {
  margin-top: 1.3rem;
  font-size: 1.3rem;
}

.hero-info__input {
  margin-top: 1rem;
  width: 100%;
  height: 4rem;
  background-color: #000;
}

.hero-info__btn {
  margin-top: 1.3rem;
  margin-inline: auto;
  font-weight: 700;
}

.hero-info__text {
  margin-top: 1rem;
  font-size: 1.3rem;
  text-align: center;
}

.hero-info__top {
  padding: clamp(1rem, 0.2727272727rem + 2.2727272727vw, 3rem);
}

.hero-info-chats {
  padding: 3rem;
  background-color: var(--clr-bg-100);
}

.hero-info-chats__caption {
  font-weight: 700;
  font-size: clamp(1.3rem, 1.0454545455rem + 0.7954545455vw, 2rem);
  text-align: center;
}

.hero-info-chats__block {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 2rem;
}

.hero-info-chats__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8rem;
  transition: box-shadow var(--transition);
  gap: 1rem;
}

.hero-info-chats__item:nth-child(1) img {
  max-width: 3.9rem;
}

.hero-info-chats__item:nth-child(2) img {
  max-width: 4.37rem;
}

.hero-info-chats__item:nth-child(3) img {
  width: 100%;
  max-width: 4.8rem;
}

.hero-info-chats__item span {
  font-size: 1.3rem;
  color: var(--clr-default-900);
}

.hero__video {
  flex-grow: 1;
}

.hero__video iframe {
  border-style: solid;
  border-width: 1rem;
  border-color: var(--clr-bg-200);
  width: 100%;
  aspect-ratio: 16/9;
}

.hero-license {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  aspect-ratio: 16/8;
}

.hero-license--img {
  background-image: url("../img/img-bg-license.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-license--img video {
  display: none;
}

.hero-license .title-main {
  font-size: clamp(2.5rem, 1.8818181818rem + 1.9318181818vw, 4.2rem);
  color: var(--clr-default-100);
}

.hero-license::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-license video {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.hero-license__wrapper {
  position: relative;
  z-index: 3;
  color: var(--clr-default-100);
}

.hero-license__text {
  margin-top: 4rem;
  max-width: 60rem;
  font-size: clamp(1.8rem, 1.5818181818rem + 0.6818181818vw, 2.4rem);
  color: #d3d3d3;
}

.hero-license__text span {
  font-weight: 700;
}

.hero-license .btn-primary {
  margin-top: 2rem;
  font-weight: 700;
  font-size: 2.2rem;
  text-transform: uppercase;
}

.hero-license__info {
  margin-top: 2rem;
  font-size: 1.6rem;
  color: #d3d3d3;
}

.services {
  background-color: var(--clr-bg-100);
}

.services__block {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 5rem;
  gap: 3rem;
}

.services__list {
  max-width: 50rem;
}

.services__list li {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}

.services__list li:not(:first-child) {
  margin-top: 2.5rem;
}

.services__list li::before {
  content: "";
  display: flex;
  margin-right: 2rem;
  border-radius: 100%;
  min-width: 1rem;
  min-height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  background-color: var(--clr-primary-100);
}

.services__img {
  display: flex;
  max-width: 50rem;
  box-shadow: 0.3rem 0.3rem 2rem 0 rgba(0, 0, 0, 0.5);
}

.steps__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4rem;
  gap: clamp(1.5rem, 0.9545454545rem + 1.7045454545vw, 3rem);
}

.steps__item {
  flex-grow: 11;
  flex-basis: 26%;
}

.steps__num {
  display: flex;
  align-items: flex-end;
  font-weight: 700;
  font-size: 5rem;
  gap: 0.5rem;
}

.steps__num::after {
  content: "";
  display: flex;
  margin-bottom: 0.9rem;
  border-radius: 100%;
  width: 0.8rem;
  height: 0.8rem;
  background-color: var(--clr-primary-100);
}

.steps__title {
  margin-top: 1rem;
  font-weight: 700;
  font-size: 2rem;
}

.steps__text {
  margin-top: 1rem;
  max-width: 90%;
  font-size: 1.6rem;
  line-height: 2.1rem;
}

.cost {
  background-color: var(--clr-bg-100);
}

.cost__block {
  display: flex;
  margin-top: 5rem;
  gap: clamp(2rem, 1.7090909091rem + 0.9090909091vw, 2.8rem);
}

.cost-item {
  display: flex;
  flex-grow: 1;
  flex-basis: 26%;
  flex-direction: column;
  padding: 3.5rem 2.8rem;
  background-color: var(--clr-bg-200);
}

.cost-item__title {
  position: relative;
  height: 5rem;
  font-size: 2rem;
  text-align: center;
  color: var(--clr-default-100);
}

.cost-item__title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2rem;
  display: flex;
  width: 10rem;
  height: 0.2rem;
  background-color: var(--clr-primary-100);
  transform: translateX(-50%);
}

.cost-item__list {
  color: var(--clr-default-100);
}

.cost-item__list li::before {
  content: "-";
  margin-right: 0.3rem;
  color: var(--clr-primary-100);
}

.cost-item__price {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  color: var(--clr-default-100);
}

.cost-item .btn-primary {
  margin-top: 2rem;
  margin-inline: auto;
  padding-block: 1.2rem;
}

.cost-life .cost-item__title::after {
  bottom: 1rem;
}

.team__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 7rem;
  gap: clamp(2rem, 0.5454545455rem + 4.5454545455vw, 6rem);
}

.team-item {
  display: flex;
  flex-grow: 1;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: clamp(1rem, 0.9272727273rem + 0.2272727273vw, 1.2rem);
}

.team-item::after {
  content: "";
  display: flex;
  order: -1;
  margin-inline: auto;
  width: 12rem;
  height: 0.2rem;
  background-color: var(--clr-primary-100);
}

.team-item__photo {
  display: flex;
  order: -3;
  width: max-content;
  width: 100%;
  max-width: 25rem;
  aspect-ratio: 1/1.1;
}

.team-item__photo img {
  width: 100%;
  aspect-ratio: 1/1.1;
}

.team-item__name {
  order: -2;
  font-weight: 700;
  font-size: 1.8rem;
}

.team-item__position {
  flex-grow: 1;
  font-size: 1.6rem;
}

.expertise {
  background-color: var(--clr-bg-100);
}

.expertise__block {
  display: flex;
  align-items: start;
  margin-top: 7rem;
  gap: 4rem;
}

.expertise__img {
  display: flex;
}

.expertise__info {
  flex-basis: 60%;
}

.expertise__text {
  font-size: clamp(1.6rem, 1.4545454545rem + 0.4545454545vw, 2rem);
  line-height: 3.1rem;
}

.expertise__text span {
  font-weight: 700;
}

.expertise__list {
  display: flex;
  flex-direction: column;
  margin-top: 2.3rem;
  font-weight: 700;
  font-size: clamp(1.6rem, 1.4545454545rem + 0.4545454545vw, 2rem);
  gap: 1rem;
}

.expertise__btn {
  margin-top: 2rem;
  border-radius: 3rem;
  padding: 1.2rem 3rem;
  box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
  font-weight: 700;
  font-size: clamp(1.6rem, 1.4545454545rem + 0.4545454545vw, 2rem);
  text-transform: uppercase;
}

.certificates {
  background-color: var(--clr-bg-100);
}

.certificates__block {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  /* Автоматическая высота строк */
  margin-top: 5rem;
  gap: 1.5rem;
}

.certificates__item {
  box-shadow: 0.2rem 0.2rem 2rem 0 rgba(0, 0, 0, 0.4);
  background-color: var(--clr-default-100);
}

.certificates__item-4 {
  grid-row: span 2;
}

.certificates__item-7 {
  grid-column: span 2;
  padding: 3.5rem;
}

.certificates__item-caption {
  max-width: 60rem;
  font-weight: 700;
  font-size: 2rem;
}

.certificates__item-logo {
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;
  gap: 3rem;
}

.certificates__item-logo img {
  max-width: clamp(2rem, -4.5454545455rem + 20.4545454545vw, 20rem);
  object-fit: contain;
}

.certificates__mobile {
  display: none;
  margin-top: 3rem;
}

.certificates__mobile .certificates__item-caption {
  font-size: 1.6rem;
}

.certificates__mobile img {
  max-width: clamp(7rem, 2.2727272727rem + 14.7727272727vw, 20rem);
  object-fit: contain;
}

.contacts__logo {
  display: flex;
  align-items: center;
  max-width: 25rem;
  gap: 1rem;
}

.contacts__logo img {
  max-width: 5rem;
}

.contacts__wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;
  gap: 4rem;
}

.contacts-info__contacts a {
  display: flex;
  font-size: 1.8rem;
  color: var(--clr-default-900);
  gap: 1rem;
}

.contacts-info__contacts a:not(:first-child) {
  margin-top: 1rem;
}

.contacts-info__contacts a svg {
  width: 2rem;
  height: 2rem;
}

.contacts-info__socials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 1rem;
}

.contacts-info__socials a {
  display: flex;
  align-items: center;
  border-radius: 3rem;
  padding: 0.5rem 1rem;
  width: 14rem;
  box-shadow: 0rem 0rem 1rem 0.2rem rgb(230, 230, 230);
  font-size: 1.3rem;
  color: var(--clr-default-900);
  gap: 0.7rem;
}

.contacts-info__socials a img {
  max-width: 3rem;
}

.contacts-info__text {
  margin-top: 10rem;
  font-size: 1.5rem;
  line-height: 150%;
}

.contacts-info__text span {
  font-weight: 700;
}

.contacts-info__btn {
  margin-top: 2rem;
  border-radius: 3rem;
  padding: 0.7rem 1rem;
  width: 100%;
  max-width: 26rem;
  box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
  font-weight: 700;
  text-transform: uppercase;
}

.contacts-list {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(0.8rem, 0.6181818182rem + 0.5681818182vw, 1.3rem);
}

.contacts-list__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  cursor: pointer;
  gap: clamp(0.8rem, 0.5454545455rem + 0.7954545455vw, 1.5rem);
}

.contacts-list__ico {
  max-width: 4rem;
  max-height: 4rem;
}

.contacts-list__ico svg {
  width: 100%;
  height: 100%;
}

.contacts-list__city {
  font-weight: 700;
  font-size: clamp(1.6rem, 1.5272727273rem + 0.2272727273vw, 1.8rem);
}

.contacts-list__address {
  margin-top: 0.3rem;
  font-size: clamp(1.6rem, 1.5272727273rem + 0.2272727273vw, 1.8rem);
}

.form {
  background-color: var(--clr-bg-200);
}

.form-military {
  position: relative;
  background-image: url("../img/military-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.form-military::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translate(-50%, -50%);
}

.form-military .form__wrapper {
  position: relative;
  z-index: 1;
}

.form .title-main {
  color: var(--clr-default-100);
}

.form__block {
  display: flex;
  justify-content: space-between;
  margin-top: clamp(3rem, 2.2727272727rem + 2.2727272727vw, 5rem);
}

.form__phone span {
  font-weight: 700;
  font-size: 2.2rem;
  color: var(--clr-default-100);
}

.form__phone a {
  display: flex;
  margin-top: 5rem;
  font-size: 2rem;
  color: var(--clr-default-100);
  gap: 1rem;
}

.form__phone a.form__phone-mt {
  margin-top: 1rem;
}

.form__item {
  max-width: 43rem;
}

.form__item form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  gap: 2rem;
}

.form__item form input {
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--clr-primary-100);
  padding: 1.5rem 1rem;
  width: 100%;
  max-width: 45rem;
  color: var(--clr-default-100);
  background-color: transparent;
}

.form__item form .btn-primary {
  border-radius: 3rem;
  width: 100%;
  max-width: 45rem;
  font-weight: 700;
  font-size: clamp(1.3rem, 1.0454545455rem + 0.7954545455vw, 2rem);
  text-transform: uppercase;
}

.form__caption {
  font-weight: 700;
  font-size: 2rem;
  color: var(--clr-default-100);
}

.form__text {
  margin-top: 3rem;
  font-size: 1.2rem;
  color: #bdbdbd;
}

.form-example .form__caption {
  margin-bottom: 8rem;
  font-weight: 400;
  font-size: clamp(1.6rem, 1.5272727273rem + 0.2272727273vw, 1.8rem);
}

.form-example .btn-primary {
  font-size: 1.6rem !important;
}

.form__example {
  max-width: 56rem;
  transform: translateX(clamp(-7rem, -9.5454545455rem + 7.9545454545vw, 0rem));
}

.feedback .swiper {
  margin-top: 3rem;
  width: 100%;
  height: 100%;
}

.feedback .swiper__prev {
  position: absolute;
  left: 50%;
  top: 12rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  background-color: var(--clr-primary-100);
  transform: translateX(-31rem);
  cursor: pointer;
}

.feedback .swiper__prev svg {
  max-width: 1.2rem;
  transform: rotate(180deg);
}

.feedback .swiper__next {
  position: absolute;
  left: 50%;
  top: 12rem;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  background-color: var(--clr-primary-100);
  transform: translateX(26rem);
  cursor: pointer;
}

.feedback .swiper__next svg {
  max-width: 1.2rem;
}

.feedback .swiper-slide {
  opacity: 0.3;
  transition: opacity var(--transition);
}

.feedback .swiper-slide__title,
.feedback .swiper-slide__text {
  opacity: 0;
  transition: opacity var(--transition);
}

.feedback .swiper-slide__title {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.feedback .swiper-slide-prev .swiper-slide__content {
  margin-inline: auto 0;
}

.feedback .swiper-slide-next .swiper-slide__content {
  margin-inline: 0 auto;
}

.feedback .swiper-slide-active {
  opacity: 1;
}

.feedback .swiper-slide-active .feedback__google,
.feedback .swiper-slide-active .swiper-slide__title,
.feedback .swiper-slide-active .swiper-slide__text {
  opacity: 1;
}

.feedback .swiper-slide__content {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 50rem;
  gap: 1rem;
}

.feedback .swiper-slide__video {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.feedback .swiper-slide__text {
  line-height: 150%;
}

.feedback .swiper-slide__img {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 100%;
  transform: translate(-50%, -50%);
}

.feedback .swiper-slide__img img {
  width: 100%;
}

.feedback .swiper-slide__play {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.feedback__google {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-inline: auto;
  padding-bottom: 1rem;
  transition: opacity var(--transition);
  gap: 2rem;
}

.feedback__google .btn-primary {
  border-radius: 3rem;
  min-width: max-content;
  box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: none;
}

.feedback__google img {
  max-width: 36rem;
}

.why {
  background-color: var(--clr-bg-200);
}

.why .title-main {
  color: var(--clr-default-100);
}

.why__subtitle {
  margin-top: 6rem;
  font-weight: 700;
  font-size: 2.2rem;
  color: var(--clr-default-100);
}

.why__block {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.why__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  gap: 2rem;
}

.why__text {
  margin-top: 1.5rem;
  font-size: clamp(1.4rem, 1.2545454545rem + 0.4545454545vw, 1.8rem);
  line-height: 2.3rem;
  color: var(--clr-default-100);
}

.why__text span {
  font-weight: 700;
}

.why__btn {
  margin-top: 2.5rem;
  border-radius: 3rem;
  padding: 1.23rem 2rem;
  box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
  font-weight: 700;
  font-size: clamp(1.4rem, 1.3272727273rem + 0.2272727273vw, 1.6rem);
  text-transform: uppercase;
}

.why__bold {
  margin-top: 2.5rem;
  max-width: 36rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--clr-default-100);
}

.why__bold span {
  color: var(--clr-primary-100);
}

.why-img {
  position: relative;
  display: flex;
  width: max-content;
  max-width: 100%;
}

.why-img__absolute {
  position: absolute;
  display: flex;
}

.why-img__absolute_1 {
  left: 7%;
  top: 10%;
}

.why-img__absolute_2 {
  right: 10%;
  top: 25%;
}

.why-img__absolute_3 {
  left: 10%;
  bottom: 34%;
}

.why-img__absolute_4 {
  left: 20%;
  top: 10%;
}

.why-img__absolute_5 {
  right: 5%;
  bottom: 20%;
}

.why-img__absolute_6 {
  left: 5%;
  bottom: 10%;
}

.why-img__text {
  position: relative;
  padding: clamp(0.2rem, -0.2727272727rem + 1.4772727273vw, 1.5rem);
  width: max-content;
  max-width: 25rem;
  font-size: clamp(1.1rem, 1.0272727273rem + 0.2272727273vw, 1.3rem);
  line-height: 150%;
  color: var(--clr-default-100);
  background-color: rgba(0, 0, 0, 0.8);
}

.why-img__text::before {
  content: "";
  position: absolute;
  display: flex;
  width: 0.2rem;
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.8);
}

.why-img__text::after {
  content: "";
  position: absolute;
  display: flex;
  border-radius: 100%;
  width: 0.8rem;
  height: 0.8rem;
  background-color: var(--clr-primary-100);
}

.why-img__text_1::before {
  left: 2rem;
  bottom: -3.8rem;
  transform: rotate(-55deg);
}

.why-img__text_1::after {
  left: 3.8rem;
  bottom: -3.2rem;
}

.why-img__text_2::before {
  left: 2rem;
  bottom: -3.8rem;
  transform: rotate(-55deg);
}

.why-img__text_2::after {
  left: 3.8rem;
  bottom: -3.2rem;
}

.why-img__text_3::before {
  left: 2rem;
  bottom: -3.8rem;
  transform: rotate(-55deg);
}

.why-img__text_3::after {
  left: 3.8rem;
  bottom: -3.2rem;
}

.why-img__text_4::before {
  left: 2rem;
  bottom: -3.8rem;
  transform: rotate(-55deg);
}

.why-img__text_4::after {
  left: 3.8rem;
  bottom: -3.2rem;
}

.why-img__text_5::before {
  left: -2rem;
  bottom: -3.8rem;
  transform: rotate(55deg);
}

.why-img__text_5::after {
  left: -4.3rem;
  bottom: -3rem;
}

.why-img__text_6::before {
  right: -2rem;
  top: -3.8rem;
  transform: rotate(55deg);
}

.why-img__text_6::after {
  right: -4.5rem;
  top: -3.2rem;
}

.result-block {
  display: flex;
  overflow-y: auto;
  margin-top: 5rem;
  padding-bottom: 2rem;
}

.result-block::-webkit-scrollbar {
  width: 0.3rem;
  height: 1rem;
}

.result-block::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-100);
}

.result-block::-webkit-scrollbar-track {
  background-color: var(--clr-bg-100);
}

.result-block__scroll {
  display: flex;
  overflow: hidden;
  border: 0.2rem solid var(--clr-primary-100);
  border-radius: 3rem;
  width: 100%;
  min-width: 97rem;
}

.result-block__item {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 8rem;
  font-size: 1.4rem;
  text-align: center;
}

.result-block__item:not(:last-child) {
  border-right: 0.2rem solid var(--clr-primary-100);
}

.result-block__item.active {
  font-weight: 700;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
}

.result-content {
  display: flex;
  margin-top: 3rem;
}

.result-content__item {
  display: flex;
  gap: 2rem;
}

.result-content__title {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.8rem;
}

.result-content__text {
  padding: 3.5rem 2.79rem;
  min-width: 30rem;
  max-width: 30rem;
  line-height: 1.8rem;
  color: var(--clr-default-100);
  background-color: var(--clr-bg-200);
}

.result-content__text p {
  margin-top: 1.7rem;
  font-size: 1.4rem;
}

.result-content__text p span {
  font-weight: 700;
  color: var(--clr-primary-100);
}

.result-content__photo {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.result-content__img {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  justify-content: center;
  border-style: solid;
  border-width: 1rem;
  border-color: var(--clr-default-100);
  box-shadow: 0.5rem 0.5rem 2rem 0rem rgba(0, 0, 0, 0.3);
  aspect-ratio: 1.2/0.9;
}

.result-content__img img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.victim {
  background-color: var(--clr-bg-100);
}

.victim__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6rem;
  gap: 2rem;
}

.victim__item {
  display: flex;
  flex-basis: 21%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  height: clamp(10rem, 8.1818181818rem + 5.6818181818vw, 15rem);
  box-shadow: 0.2rem 0.2rem 2rem 0rem rgba(0, 0, 0, 0.3);
  background-color: var(--clr-default-100);
  transition: transform var(--transition);
}

.victim__item_hover:hover {
  transform: translateY(-2rem);
}

.victim__item_hover:hover > * {
  transform: translateY(2rem);
}

.victim__item > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform var(--transition);
  gap: 1rem;
}

.victim__item img {
  width: 3rem;
}

.victim__item p {
  font-weight: 400;
}

.victim-license {
  background-color: var(--clr-default-100);
}

.victim-license .victim__item {
  justify-content: start;
  height: auto;
  min-height: 20rem;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: center;
}

.google-feedback__wrapper {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  gap: 2rem;
}

.google-feedback__nav {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.google-feedback__logo {
  max-width: 20rem;
}

.google-feedback__btn {
  border-radius: 3rem;
  box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
  font-weight: 700;
  text-transform: none;
}

.help .title-main {
  text-align: center;
}

.help .title-main::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.help__subtitle {
  margin-top: 3rem;
  font-size: clamp(1.6rem, 1.3818181818rem + 0.6818181818vw, 2.2rem);
  text-align: center;
}

.help__block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  gap: clamp(2rem, 0.9090909091rem + 3.4090909091vw, 5rem);
}

.help__item {
  display: flex;
  flex-grow: 1;
  flex-basis: 34%;
  gap: 2rem;
}

.help__text {
  max-width: 80%;
  font-weight: 700;
  font-size: clamp(1.6rem, 1.3818181818rem + 0.6818181818vw, 2.2rem);
}

.help__ico {
  width: 7rem;
}

.help__btn {
  margin-top: 4rem;
  margin-inline: auto;
  border-radius: 3rem;
  padding: 1.5rem 3rem;
  font-weight: 700;
  font-size: clamp(1.6rem, 1.4545454545rem + 0.4545454545vw, 2rem);
  text-align: center;
}

.about {
  color: var(--clr-default-100);
  background-color: var(--clr-bg-200);
}

.about__wrapper {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.about__text {
  margin-top: 5rem;
  max-width: 70rem;
  font-size: clamp(1.6rem, 1.3818181818rem + 0.6818181818vw, 2.2rem);
}

.about__logo {
  width: clamp(10rem, 6.3636363636rem + 11.3636363636vw, 20rem);
}

.caces .title-main {
  text-align: center;
}

.caces .title-main::after {
  position: absolute;
  left: 50%;
  width: 12rem;
  transform: translate(-50%, -50%);
}

.caces-block {
  display: flex;
  overflow-y: auto;
  margin-top: 5rem;
}

.caces-block::-webkit-scrollbar {
  width: 0.3rem;
  height: 1rem;
}

.caces-block::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-100);
}

.caces-block::-webkit-scrollbar-track {
  background-color: var(--clr-bg-100);
}

.caces-block__scroll {
  display: flex;
  overflow: hidden;
  margin-bottom: 2rem;
  border: 0.1rem solid var(--clr-default-900);
  width: 100%;
  min-width: 97rem;
}

.caces-block__item {
  display: flex;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 5rem;
}

.caces-block__item:not(:last-child) {
  border-right: 0.1rem solid var(--clr-default-900);
}

.caces-block__item.active {
  font-weight: 700;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
}

.caces-content {
  margin-top: 4rem;
}

.caces-content__item {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.caces-content__text {
  flex-basis: 40%;
  font-size: clamp(1.6rem, 1.4545454545rem + 0.4545454545vw, 2rem);
  line-height: 150%;
}

.caces-content__wrap {
  display: flex;
  flex-basis: 55%;
  gap: 1rem;
}

.caces-content__img {
  position: relative;
  flex-grow: 1;
  flex-basis: 34%;
  overflow: hidden;
}

.caces-content__img_hover::after {
  content: "";
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: opacity var(--transition);
}

.caces-content__img_hover:hover::after {
  opacity: 0.7;
}

.caces-content__img_hover:hover .caces-content__img-link {
  opacity: 1;
}

.caces-content__img img {
  position: absolute;
  left: 50%;
  top: 50%;
  min-height: 100%;
  transform: translate(-50%, -50%);
}

.caces-content__img-link {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-default-100);
  border-radius: 3rem;
  padding: 1rem;
  min-width: 22rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--clr-default-100);
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity var(--transition);
}

.protected {
  display: flex;
  align-items: center;
  height: 5rem;
  background-color: #323232;
}

.protected__text {
  font-size: 1.4rem;
  color: #fff;
}

@media (max-width: 75em) {
  .cost-life .cost-item__title::after {
    display: none;
  }
}

@media (max-width: 62em) {
  .menu__list {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 2rem;
  }

  .menu__list .menu__close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    width: 4rem;
    height: 4rem;
  }

  .menu__list .menu__close-rel {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .menu__list .menu__close-rel::after,
  .menu__list .menu__close-rel::before {
    content: "";
    position: absolute;
    width: 3rem;
    height: 0.1rem;
    background-color: var(--clr-default-100);
  }

  .menu__list .menu__close-rel::before {
    right: 0;
    top: 2rem;
    transform: rotate(-45deg);
  }

  .menu__list .menu__close-rel::after {
    right: 0;
    top: 2rem;
    transform: rotate(45deg);
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 4rem;
    padding-block: 0;
  }

  .menu__item:hover::after {
    display: none;
  }

  .menu__item_hover {
    flex-direction: column;
    align-items: start;
  }

  .menu__item_hover::before {
    display: none;
  }

  .menu {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    padding: 3.5rem;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: rgb(17, 17, 17);
    transition: right var(--transition);
  }

  .menu--active {
    right: 0;
    display: flex;
    flex-direction: column;
    transition: right var(--transition);
  }

  .menu-dropdown {
    position: static;
    display: block;
    width: auto;
    background-color: transparent;
    transform: translateX(0);
  }

  .menu-dropdown::before {
    display: none;
  }

  .menu-dropdown a {
    color: var(--clr-default-100);
  }

  .menu-socials {
    display: flex;
  }

  .menu__btn-phone {
    display: flex;
  }

  .burger {
    position: relative;
    z-index: 15;
    display: block;
    width: 2.5rem;
    height: 2rem;
    background-color: transparent;
  }

  .burger::after,
  .burger::before,
  .burger span {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background-color: #fff;
  }

  .burger::before {
    top: 0;
    transition: transform var(--transition), top var(--transition);
  }

  .burger::after {
    bottom: 0;
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger span {
    top: 0.8rem;
    transition: transform var(--transition);
  }

  .burger--active::before {
    top: 0.8rem;
    transform: rotate(45deg);
    transition: transform var(--transition), top var(--transition);
  }

  .burger--active::after {
    bottom: 0.8rem;
    transform: rotate(-45deg);
    transition: transform var(--transition), bottom var(--transition);
  }

  .burger--active span {
    transform: scale(0);
    transition: transform var(--transition);
  }

  .why-img__absolute_2 {
    right: -27%;
    top: 30%;
  }

  .why-img__absolute_3 {
    left: 6%;
    bottom: 19%;
  }

  .why-img__absolute_4 {
    left: 5%;
    top: 8%;
  }

  .why-img__absolute_5 {
    right: -34%;
    bottom: 14%;
  }

  .why-img__absolute_6 {
    left: 4%;
    bottom: 29%;
  }

  .why-img__text_2 {
    max-width: 16rem;
  }

  .why-img__text_3 {
    max-width: 16rem;
  }

  .why-img__text_5 {
    max-width: 16rem;
  }

  .why-img__text_6 {
    max-width: 14rem;
  }
}

@media (max-width: 62em) and (max-width: 43.75em) {
  .why-img__absolute_5 {
    right: -50%;
  }
}

@media (max-width: 48em) {
  .faq {
    padding-top: 8rem;
  }
}

@media (max-width: 36em) {
  .faq__item-title {
    padding: 2rem 6rem 2rem 2rem;
  }

  .faq__item-body > div {
    padding: 0 2rem 2rem 2rem;
  }
}

@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}

@media (max-width: 30em) {
  .form__example {
    min-width: clamp(40rem, 33.4545454545rem + 20.4545454545vw, 58rem);
    transform: translateX(clamp(-8rem, -10.9090909091rem + 9.0909090909vw, 0rem));
  }
}

@media (max-width: 25em) {
  .why__btn {
    padding: 1rem;
  }
}

@media (max-width: 65.625em) {
  .caces-content__item {
    display: flex;
    flex-direction: column;
  }

  .caces-content__wrap {
    min-height: 50rem;
  }
}

@media (max-width: 65.625em) and (max-width: 37.5em) {
  .caces-content__wrap {
    flex-direction: column;
    min-height: 100rem;
    gap: 1rem;
  }
}

@media (max-width: 64.375em) {
  .hero__block {
    flex-direction: column-reverse;
  }

  .hero__video {
    width: 86%;
    max-width: 60rem;
  }
}

@media (max-width: 64.0625em) {
  .certificates__item-7 {
    display: none;
  }

  .certificates__mobile {
    display: block;
  }
}

@media (max-width: 62.5em) {
  .header-info__city {
    display: flex;
  }

  .hero__header {
    justify-content: center;
  }

  .hero__city {
    display: none;
  }

  .hero__text {
    max-width: 100%;
    text-align: center;
  }
}

@media (max-width: 61.25em) {
  .hero-life .hero__title {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 61.5625em) {
  .why__block {
    flex-direction: column;
  }

  .why__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .why__item:not(:first-child) {
    flex-direction: column-reverse;
  }

  .why__item div {
    width: 100%;
  }

  .why__text {
    width: 100%;
  }
}

@media (max-width: 60.625em) {
  .contacts__wrap {
    flex-direction: column;
  }

  .contacts-info__socials {
    justify-content: flex-start;
  }

  .contacts-info__text {
    margin-top: 3rem;
  }
}

@media (max-width: 59.375em) {
  .menu__btn {
    display: flex;
  }

  .menu__tel {
    display: flex;
  }

  .menu__address {
    display: flex;
  }

  .header .btn-primary {
    display: none;
  }

  .cost__block {
    flex-wrap: wrap;
    justify-content: center;
  }

  .cost-item {
    flex-basis: 34%;
    max-width: 35rem;
  }
}

@media (max-width: 59.375em) and (max-width: 59.375em) {
  .cost-item {
    flex-basis: 100%;
  }
}

@media (max-width: 58.75em) {
  .hero-license {
    padding-block: 15rem;
    aspect-ratio: auto;
  }

  .hero-license video {
    left: 50%;
    top: 50%;
    height: 120%;
    transform: translate(-50%, -50%);
  }

  .help__item {
    flex-basis: 100%;
  }
}

@media (max-width: 57.5em) {
  .form__block {
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
  }

  .form-example .form__caption {
    margin-bottom: 2rem;
  }
}

@media (max-width: 56.25em) {
  .services__block {
    flex-direction: column-reverse;
  }

  .services__list {
    max-width: 100%;
  }

  .services__img {
    display: none;
  }

  .steps__item {
    flex-basis: 34%;
  }

  .expertise__block {
    flex-direction: column;
    gap: 2rem;
  }
}

@media (max-width: 56.25em) and (max-width: 39.375em) {
  .steps__item {
    flex-basis: 100%;
  }
}

@media (max-width: 54.6875em) {
  .team-item {
    flex-basis: 34%;
    text-align: center;
  }
}

@media (max-width: 54.375em) {
  .about__wrapper {
    flex-direction: column-reverse;
  }

  .about__text {
    margin-top: 3rem;
    max-width: 100%;
  }
}

@media (max-width: 53.125em) {
  .feedback__google {
    flex-direction: column;
  }

  .result-content__item {
    flex-direction: column;
  }

  .result-content__text {
    min-width: 100%;
    max-width: 100%;
  }

  .google-feedback__wrapper {
    flex-direction: column;
  }

  .google-feedback__nav {
    align-items: center;
  }
}

@media (max-width: 48.75em) {
  .victim__item {
    flex-grow: 1;
    flex-basis: 34%;
  }
}

@media (max-width: 47.9375em) {
  .faq__item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 40.625em) {
  .feedback .swiper__prev {
    left: 0;
    width: 3.5rem;
    height: 3.5rem;
    transform: translateX(0);
  }

  .feedback .swiper__next {
    left: auto;
    right: 0;
    width: 3.5rem;
    height: 3.5rem;
    transform: translateX(0);
  }

  .feedback .swiper-slide {
    padding: 1rem;
  }
}

@media (max-width: 37.5em) {
  .dots-bg_form {
    right: -20%;
    top: 80%;
  }

  .hero-life .hero__title-svg {
    position: absolute;
    left: 50%;
    top: -1rem;
    transform: translate(-50%, -50%);
  }

  .help__ico {
    width: 5rem;
  }

  .protected {
    display: none;
  }
}

@media (max-width: 36.25em) {
  .help__btn {
    width: 100%;
  }
}

@media (max-width: 33.4375em) {
  .logo__text {
    display: none;
  }
}

@media (max-width: 31.25em) {
  .hero {
    padding-bottom: 0;
  }

  .hero .container {
    padding: 0;
  }

  .hero__title {
    margin-top: 2rem;
  }

  .hero-info__top {
    padding: 2rem 1rem 3rem;
  }

  .certificates__block {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    /* Автоматическая высота строк */
  }
}

@media (max-width: 31.875em) {
  .contacts-list__item {
    flex-basis: 100%;
  }
}

@media (min-resolution: 2x) {
  .iti__flag {
    background-image: var(--iti-path-flags-2x);
  }

  .iti__globe {
    background-image: var(--iti-path-globe-2x);
  }
}