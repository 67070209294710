.expertise {
  background-color: var(--clr-bg-100);

  &__block {
    display: flex;
    align-items: start;
    margin-top: 7rem;
    gap: 4rem;

    @media (max-width: em(900)) {
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__img {
    display: flex;
  }

  &__info {
    flex-basis: 60%;
  }

  &__text {
    font-size: fluid(2rem, 1.6rem);
    line-height: 3.1rem;

    span {
      font-weight: 700;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-top: 2.3rem;
    font-weight: 700;
    font-size: fluid(2rem, 1.6rem);

    gap: 1rem;
  }

  &__btn {
    margin-top: 2rem;
    border-radius: 3rem;
    padding: 1.2rem 3rem;
    box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
    font-weight: 700;
    font-size: fluid(2rem, 1.6rem);

    text-transform: uppercase;
  }
}
