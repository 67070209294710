.why {
  background-color: var(--clr-bg-200);

  .title-main {
    color: var(--clr-default-100);
  }

  &__subtitle {
    margin-top: 6rem;
    font-weight: 700;
    font-size: 2.2rem;
    color: var(--clr-default-100);
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media (max-width: em(985)) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    flex-grow: 1;
    flex-basis: 34%;
    gap: 2rem;

    @media (max-width: em(985)) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:first-child) {
        flex-direction: column-reverse;
      }

      div {
        width: 100%;
      }
    }
  }

  &__text {
    margin-top: 1.5rem;
    font-size: fluid(1.8rem, 1.4rem);
    line-height: 2.3rem;
    color: var(--clr-default-100);

    @media (max-width: em(985)) {
      width: 100%;
    }

    span {
      font-weight: 700;
    }
  }

  &__btn {
    margin-top: 2.5rem;
    border-radius: 3rem;
    padding: 1.23rem 2rem;
    box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
    font-weight: 700;
    font-size: fluid(1.6rem, 1.4rem);
    text-transform: uppercase;

    @media (max-width: em(400)) {
      padding: 1rem;
    }
  }

  &__bold {
    margin-top: 2.5rem;
    max-width: 36rem;
    font-weight: 700;
    font-size: 2rem;
    color: var(--clr-default-100);

    span {
      color: var(--clr-primary-100);
    }
  }

  &-img {
    position: relative;
    display: flex;
    width: max-content;
    max-width: 100%;

    &__absolute {
      position: absolute;
      display: flex;

      &_1 {
        left: 7%;
        top: 10%;
      }

      &_2 {
        right: 10%;
        top: 25%;

        @media (max-width: em(992)) {
          right: -27%;
          top: 30%;
        }
      }

      &_3 {
        left: 10%;
        bottom: 34%;

        @media (max-width: em(992)) {
          left: 6%;
          bottom: 19%;
        }
      }

      &_4 {
        left: 20%;
        top: 10%;

        @media (max-width: em(992)) {
          left: 5%;
          top: 8%;
        }
      }

      &_5 {
        right: 5%;
        bottom: 20%;

        @media (max-width: em(992)) {
          right: -34%;
          bottom: 14%;

          @media (max-width: em(700)) {
            right: -50%;
          }
        }
      }

      &_6 {
        left: 5%;
        bottom: 10%;

        @media (max-width: em(992)) {
          left: 4%;
          bottom: 29%;
        }
      }
    }

    &__text {
      position: relative;
      padding: fluid(1.5rem, 0.2rem);
      width: max-content;
      max-width: 25rem;
      font-size: fluid(1.3rem, 1.1rem);
      line-height: 150%;
      color: var(--clr-default-100);
      background-color: rgba(0, 0, 0, 0.8);

      &::before {
        content: "";
        position: absolute;
        display: flex;
        width: 0.2rem;
        height: 5rem;
        background-color: rgba(0, 0, 0, 0.8);
      }

      &::after {
        content: "";
        position: absolute;
        display: flex;
        border-radius: 100%;
        width: 0.8rem;
        height: 0.8rem;
        background-color: var(--clr-primary-100);
      }

      &_1 {
        &::before {
          left: 2rem;
          bottom: -3.8rem;
          transform: rotate(-55deg);
        }

        &::after {
          left: 3.8rem;
          bottom: -3.2rem;
        }
      }

      &_2 {
        @media (max-width: em(992)) {
          max-width: 16rem;
        }

        &::before {
          left: 2rem;
          bottom: -3.8rem;
          transform: rotate(-55deg);
        }

        &::after {
          left: 3.8rem;
          bottom: -3.2rem;
        }
      }

      &_3 {
        @media (max-width: em(992)) {
          max-width: 16rem;
        }

        &::before {
          left: 2rem;
          bottom: -3.8rem;
          transform: rotate(-55deg);
        }

        &::after {
          left: 3.8rem;
          bottom: -3.2rem;
        }
      }

      &_4 {
        &::before {
          left: 2rem;
          bottom: -3.8rem;
          transform: rotate(-55deg);
        }

        &::after {
          left: 3.8rem;
          bottom: -3.2rem;
        }
      }

      &_5 {
        @media (max-width: em(992)) {
          max-width: 16rem;
        }

        &::before {
          left: -2rem;
          bottom: -3.8rem;
          transform: rotate(55deg);
        }

        &::after {
          left: -4.3rem;
          bottom: -3rem;
        }
      }

      &_6 {
        @media (max-width: em(992)) {
          max-width: 14rem;
        }

        &::before {
          right: -2rem;
          top: -3.8rem;
          transform: rotate(55deg);
        }

        &::after {
          right: -4.5rem;
          top: -3.2rem;
        }
      }
    }
  }
}
