.certificates {
  background-color: var(--clr-bg-100);

  &__block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto); /* Автоматическая высота строк */
    margin-top: 5rem;
    gap: 1.5rem;

    @media (max-width: em(500)) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto); /* Автоматическая высота строк */
    }
  }

  &__item {
    box-shadow: 0.2rem 0.2rem 2rem 0 rgba(0, 0, 0, 0.4);
    background-color: var(--clr-default-100);

    &-4 {
      grid-row: span 2;
    }

    &-7 {
      grid-column: span 2;
      padding: 3.5rem;

      @media (max-width: em(1025)) {
        display: none;
      }
    }

    &-caption {
      max-width: 60rem;
      font-weight: 700;
      font-size: 2rem;
    }

    &-logo {
      display: flex;
      align-items: flex-start;
      margin-top: 3rem;
      gap: 3rem;

      img {
        max-width: fluid(20rem, 2rem);
        object-fit: contain;
      }
    }
  }

  &__mobile {
    display: none;
    margin-top: 3rem;

    @media (max-width: em(1025)) {
      display: block;
    }

    .certificates__item-caption {
      font-size: 1.6rem;
    }

    img {
      max-width: fluid(20rem, 7rem);
      object-fit: contain;
    }
  }
}
