.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  background-color: #323232;

  .container {
    z-index: 20;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.7rem;

    &__city {
      display: none;
      font-weight: 700;
      font-size: 1.4rem;
      color: var(--clr-default-100);

      @media (max-width: em(1000)) {
        display: flex;
      }
    }

    &__text {
      font-size: 1.4rem;
      text-align: right;
      color: #b0b0b0;
    }

    &__tel {
      position: relative;
      font-weight: normal;
      color: var(--clr-default-100);

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 93%;
        display: flex;
        width: 0;
        height: 0.1rem;
        background-color: var(--clr-default-100);
        transform: translateX(-50%);
        transition: width var(--transition);
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  &__burger {
    position: fixed;
    right: 1rem;
    top: 2rem;
    z-index: 22;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 4.11rem;
    height: 4.11rem;
    background-color: var(--clr-primary-100);
  }

  .btn-primary {
    border-radius: 2rem;
    padding: 0.8rem;
    width: 100%;
    max-width: fluid(23rem, 5rem);
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: uppercase;

    @media (max-width: em(950)) {
      display: none;
    }
  }
}
