.cost {
  background-color: var(--clr-bg-100);

  &__block {
    display: flex;
    margin-top: 5rem;
    gap: fluid(2.8rem, 2rem);

    @media (max-width: em(950)) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &-item {
    display: flex;
    flex-grow: 1;
    flex-basis: 26%;
    flex-direction: column;
    padding: 3.5rem 2.8rem;
    background-color: var(--clr-bg-200);

    @media (max-width: em(950)) {
      flex-basis: 34%;
      max-width: 35rem;

      @media (max-width: em(950)) {
        flex-basis: 100%;
      }
    }

    &__title {
      position: relative;
      height: 5rem;
      font-size: 2rem;
      text-align: center;
      color: var(--clr-default-100);

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -2rem;
        display: flex;
        width: 10rem;
        height: 0.2rem;
        background-color: var(--clr-primary-100);
        transform: translateX(-50%);
      }
    }

    &__list {
      color: var(--clr-default-100);

      li {
        &::before {
          content: "-";
          margin-right: 0.3rem;
          color: var(--clr-primary-100);
        }
      }
    }

    &__price {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      margin-top: 5rem;
      font-weight: 700;
      font-size: 2rem;
      text-align: center;
      color: var(--clr-default-100);
    }

    .btn-primary {
      margin-top: 2rem;
      margin-inline: auto;
      padding-block: 1.2rem;
    }
  }

  &-life {
    .cost-item__title::after {
      bottom: 1rem;

      @media (max-width: em(1200)) {
        display: none;
      }
    }
  }
}
