.about {
  color: var(--clr-default-100);
  background-color: var(--clr-bg-200);

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media (max-width: em(870)) {
      flex-direction: column-reverse;
    }
  }

  &__text {
    margin-top: 5rem;
    max-width: 70rem;
    font-size: fluid(2.2rem, 1.6rem);

    @media (max-width: em(870)) {
      margin-top: 3rem;
      max-width: 100%;
    }
  }

  &__logo {
    width: fluid(20rem, 10rem);
  }
}
