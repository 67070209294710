.hero {
  position: relative;
  padding-block: 2.2rem 5rem;
  background-image: url("../img/hero-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &-life {
    background-image: url("../img/hero-bg-life.jpg");

    .hero__title {
      position: relative;
      padding-right: 3rem;
      max-width: 99rem;
      font-size: fluid(3.5rem, 2rem);

      @media (max-width: em(980)) {
        display: flex;
        flex-direction: column;
      }

      &-svg {
        position: absolute;
        right: 0rem;
        top: 0;
        display: inline-block;

        @media (max-width: em(600)) {
          position: absolute;
          left: 50%;
          top: -1rem;
          transform: translate(-50%, -50%);
        }

        &:hover .hero__title-svg-text {
          display: block;
        }

        &-text {
          position: absolute;
          right: 0;
          top: 100%;
          display: none;
          border-radius: 1rem;
          padding: 1rem;
          width: 27rem;
          font-weight: 400;
          font-size: 1.4rem;
          text-align: left;
          color: var(--clr-default-900);
          background-color: #fff;

          span {
            color: var(--clr-primary-100);
          }
        }
      }

      svg {
        border-radius: 100%;
        width: 3rem;
        height: 3rem;
        background-color: var(--clr-primary-100);
      }
    }
  }

  @media (max-width: em(500)) {
    padding-bottom: 0;
  }

  .container {
    @media (max-width: em(500)) {
      padding: 0;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(28, 28, 28, 0.8),
      rgba(28, 28, 28, 0.8)
    );
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: em(1000)) {
      justify-content: center;
    }
  }

  &__city {
    border-style: solid;
    border-width: 0.1rem;
    border-color: var(--clr-default-100);
    border-radius: 3rem;
    padding-block: 1.5rem;
    width: 30rem;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    color: var(--clr-default-100);

    @media (max-width: em(1000)) {
      display: none;
    }
  }

  &__text {
    max-width: 27.3rem;
    font-size: fluid(1.6rem, 1rem);
    text-align: right;
    color: var(--clr-default-100);

    @media (max-width: em(1000)) {
      max-width: 100%;
      text-align: center;
    }
  }

  &__title {
    margin-top: 1rem;
    margin-inline: auto;
    max-width: 68.5rem;
    font-size: fluid(4rem, 2rem);
    text-align: center;
    color: var(--clr-default-100);

    @media (max-width: em(500)) {
      margin-top: 2rem;
    }
  }

  &__subtitle {
    margin-top: 2rem;
    font-size: fluid(2rem, 1.4rem);
    text-align: center;
    color: var(--clr-default-100);
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: fluid(5rem, 2rem);
    gap: 3rem;

    @media (max-width: em(1030)) {
      flex-direction: column-reverse;
    }
  }

  &-info {
    flex-basis: 42%;
    background-color: var(--clr-default-100);

    &__title {
      font-size: fluid(2rem, 1.5rem);
      text-align: center;

      span {
        text-transform: uppercase;
        color: var(--clr-primary-100);
      }
    }

    &__caption {
      margin-top: 1.3rem;
      font-size: 1.3rem;
    }

    &__input {
      margin-top: 1rem;
      width: 100%;
      height: 4rem;
      background-color: #000;
    }

    &__btn {
      margin-top: 1.3rem;
      margin-inline: auto;
      font-weight: 700;
    }

    &__text {
      margin-top: 1rem;
      font-size: 1.3rem;
      text-align: center;
    }

    &__top {
      padding: fluid(3rem, 1rem);

      @media (max-width: em(500)) {
        padding: 2rem 1rem 3rem;
      }
    }

    &-chats {
      padding: 3rem;
      background-color: var(--clr-bg-100);

      &__caption {
        font-weight: 700;
        font-size: fluid(2rem, 1.3rem);
        text-align: center;
      }

      &__block {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        gap: 2rem;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 8rem;
        transition: box-shadow var(--transition);
        gap: 1rem;

        &:nth-child(1) {
          img {
            max-width: 3.9rem;
          }
        }

        &:nth-child(2) {
          img {
            max-width: 4.37rem;
          }
        }

        &:nth-child(3) {
          img {
            width: 100%;
            max-width: 4.8rem;
          }
        }

        span {
          font-size: 1.3rem;
          color: var(--clr-default-900);
        }
      }
    }
  }

  &__video {
    flex-grow: 1;

    @media (max-width: em(1030)) {
      width: 86%;
      max-width: 60rem;
    }

    iframe {
      border-style: solid;
      border-width: 1rem;
      border-color: var(--clr-bg-200);
      width: 100%;
      aspect-ratio: 16/9;
    }
  }

  &-license {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    aspect-ratio: 16/8;

    @media (max-width: em(940)) {
      padding-block: 15rem;
      aspect-ratio: auto;
    }

    &--img {
      background-image: url("../img/img-bg-license.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      video {
        display: none;
      }
    }

    .title-main {
      font-size: fluid(4.2rem, 2.5rem);
      color: var(--clr-default-100);
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    video {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      transform: translate(-50%, -50%);
      pointer-events: none;

      @media (max-width: em(940)) {
        left: 50%;
        top: 50%;
        height: 120%;

        transform: translate(-50%, -50%);
      }
    }

    &__wrapper {
      position: relative;
      z-index: 3;
      color: var(--clr-default-100);
    }

    &__text {
      margin-top: 4rem;
      max-width: 60rem;
      font-size: fluid(2.4rem, 1.8rem);
      color: #d3d3d3;

      span {
        font-weight: 700;
      }
    }

    .btn-primary {
      margin-top: 2rem;
      font-weight: 700;
      font-size: 2.2rem;
      text-transform: uppercase;
    }

    &__info {
      margin-top: 2rem;
      font-size: 1.6rem;
      color: #d3d3d3;
    }
  }
}
