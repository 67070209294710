$burgerMQ: em(992);

.menu {
  padding-inline: 1rem;

  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 2rem;

      .menu__close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        display: flex;
        width: 4rem;
        height: 4rem;

        &-rel {
          position: relative;
          width: 100%;
          height: 100%;

          &::after,
          &::before {
            content: "";
            position: absolute;
            width: 3rem;
            height: 0.1rem;
            background-color: var(--clr-default-100);
          }

          &::before {
            right: 0;
            top: 2rem;
            transform: rotate(-45deg);
          }

          &::after {
            right: 0;
            top: 2rem;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  &__item {
    position: relative;
    padding-block: 2rem;

    &:not(:last-child) {
      margin-right: 2.5rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 4rem;
        padding-block: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      display: flex;
      width: 0;
      height: 0.1rem;
      background-color: var(--clr-default-100);
      transform: translate(-50%, 1rem);
      transition: width var(--transition);
    }

    &:hover::after {
      width: 100%;
      @media (max-width: $burgerMQ) {
        display: none;
      }
    }

    &_hover {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      @media (max-width: $burgerMQ) {
        flex-direction: column;
        align-items: start;
      }

      &::before {
        content: "";
        order: 1;
        border-style: solid;
        border-width: 4px 3px 0 3px;
        border-color: var(--clr-default-100) transparent transparent transparent;
        transform: rotate(0deg);
        transition: transform var(--transition);

        @media (max-width: $burgerMQ) {
          display: none;
        }
      }

      &:hover {
        &::before {
          transform: rotate(-180deg);
        }

        .menu-dropdown {
          display: block;
        }
      }
    }
  }

  &__link {
    font-size: 1.6rem;
    text-transform: uppercase;
    color: var(--clr-default-100);
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    padding: 3.5rem;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: rgb(17, 17, 17);
    transition: right var(--transition);

    &--active {
      right: 0;
      display: flex;
      flex-direction: column;
      transition: right var(--transition);
    }
  }

  &-dropdown {
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 2;
    display: none;
    border-radius: 1rem;
    padding: 1rem;
    width: 15rem;
    background-color: var(--clr-default-100);
    transform: translateX(-50%);

    @media (max-width: $burgerMQ) {
      position: static;
      display: block;
      width: auto;
      background-color: transparent;
      transform: translateX(0);
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      border-style: solid;
      border-width: 0 1rem 1.3rem 1rem;
      border-color: transparent transparent var(--clr-default-100) transparent;
      transform: rotate(0deg) translate(-50%, -1rem);

      @media (max-width: $burgerMQ) {
        display: none;
      }
    }

    li {
      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    a {
      display: flex;
      font-weight: 700;
      font-size: 1.3rem;
      text-align: center;
      color: var(--clr-default-900);

      @media (max-width: $burgerMQ) {
        color: var(--clr-default-100);
      }
    }
  }

  &__btn {
    display: none;

    @media (max-width: em(950)) {
      display: flex;
    }
  }

  &__tel {
    display: none;
    color: var(--clr-default-100);

    @media (max-width: em(950)) {
      display: flex;
    }
  }

  &__address {
    display: none;
    margin-top: 4rem;
    color: var(--clr-default-100);

    @media (max-width: em(950)) {
      display: flex;
    }
  }

  &-socials {
    display: none;
    justify-content: space-around;
    margin-top: 3rem;
    gap: 1rem;

    @media (max-width: $burgerMQ) {
      display: flex;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 5rem;
      font-size: 1.2rem;

      color: var(--clr-default-100);
      gap: 1rem;

      img {
        max-width: 80%;
      }
    }
  }

  &__btn-phone {
    display: none;
    justify-content: center;
    margin-top: auto;
    border-radius: 3rem;
    padding: 1.5rem 2.4rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #fff;
    background-color: var(--clr-primary-100);

    @media (max-width: $burgerMQ) {
      display: flex;
    }
  }
}
