.faq {
  padding-top: 10rem;

  @media (max-width: em(768)) {
    padding-top: 8rem;
  }

  &__list {
    margin-top: 4rem;
  }

  &__item {
    position: relative;
    background-color: var(--clr-bg-100);

    &:not(:last-child) {
      margin-bottom: 0.3rem;
      transition: margin var(--transition);

      @media (max-width: em(767)) {
        margin-bottom: 1.5rem;
      }
    }

    &-title {
      position: relative;
      border-radius: var(--radius);
      padding: 2rem 10rem 2rem 4rem;
      width: 100%;
      font-weight: 700;
      font-size: fluid(1.8rem, 1.6rem);
      line-height: 135%;
      background-color: var(--clr-bg-100);
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        right: 2rem;
        top: 2rem;
        border-radius: 100%;
        width: 3rem;
        height: 3rem;
        font-size: 5rem;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path fill="white" stroke="white" stroke-width="6" d="M220,128a4.0002,4.0002,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4.0002,4.0002,0,0,1,220,128Z"/></svg>');
        background-color: var(--clr-primary-100);
        transition: background-color var(--transition),
          transform var(--transition);
      }

      @media (max-width: em(576)) {
        padding: 2rem 6rem 2rem 2rem;
      }
    }

    &-body {
      overflow: hidden;
      max-height: 0;
      box-sizing: content-box;
      line-height: 160%;
      transition: max-height var(--transition);

      & > div {
        margin-top: 0;
        padding: 0 4rem 2rem;
        font-weight: 400;
        font-style: normal;
        font-size: fluid(1.8rem, 1.4rem);
        font-size: 1.6rem;
        line-height: 155%;
        letter-spacing: 0.036rem;

        @media (max-width: em(576)) {
          padding: 0 2rem 2rem 2rem;
        }

        ul {
          margin-top: 1rem;
          padding-left: 2rem;
        }

        li {
          position: relative;

          span {
            font-weight: 700;
          }

          &::before {
            content: "";
            position: absolute;
            left: -1.8rem;
            top: 0rem;
            margin-top: 1rem;
            border-radius: 100%;
            min-width: 0.5rem;
            min-height: 0.5rem;
            max-width: 0.5rem;
            max-height: 0.5rem;
            background-color: var(--clr-default-900);
          }
        }

        li,
        div {
          font-size: 1.6rem;
        }
      }
    }

    &-red,
    &-bold {
      display: block;
      margin-top: 1rem;
      font-weight: 700;
    }

    &-red {
      color: var(--clr-primary-100);
    }

    &--active {
      .faq__item-title {
        &::after {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path fill="black" d="M220,128a4.0002,4.0002,0,0,1-4,4H132v84a4,4,0,0,1-8,0V132H40a4,4,0,0,1,0-8h84V40a4,4,0,0,1,8,0v84h84A4.0002,4.0002,0,0,1,220,128Z"/></svg>');
          background-color: transparent;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
