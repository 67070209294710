.services {
  background-color: var(--clr-bg-100);

  &__block {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 5rem;
    gap: 3rem;

    @media (max-width: em(900)) {
      flex-direction: column-reverse;
    }
  }

  &__list {
    max-width: 50rem;

    @media (max-width: em(900)) {
      max-width: 100%;
    }

    li {
      display: flex;
      align-items: center;
      font-size: 1.8rem;

      &:not(:first-child) {
        margin-top: 2.5rem;
      }

      &::before {
        content: "";
        display: flex;
        margin-right: 2rem;
        border-radius: 100%;
        min-width: 1rem;
        min-height: 1rem;
        max-width: 1rem;
        max-height: 1rem;
        background-color: var(--clr-primary-100);
      }
    }
  }

  &__img {
    display: flex;
    max-width: 50rem;
    box-shadow: 0.3rem 0.3rem 2rem 0 rgba(0, 0, 0, 0.5);

    @media (max-width: em(900)) {
      display: none;
    }
  }
}
