.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.btn-primary {
  display: flex;
  justify-content: center;
  border-style: solid;
  border-width: 0.1rem;
  border-color: var(--clr-primary-100);
  border-radius: 3.2rem;
  padding: 1.5rem 2.3rem;
  width: max-content;
  box-shadow: 0 0 0 0 transparent;
  font-weight: 700;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--clr-default-100);
  background-color: var(--clr-primary-100);
  transition: border-color var(--transition), background-color var(--transition),
    color var(--transition), box-shadow var(--transition);

  &:hover {
    background-color: #bd131e;
  }

  &:focus {
    box-shadow: 0 0 1rem 0.1rem rgba(236, 34, 48, 0.68);
    color: var(--clr-default-100);
    background-color: var(--clr-primary-100);
  }
}

.title-main {
  position: relative;
  font-weight: 700;
  font-size: fluid(4.2rem, 3rem);
  color: #313131;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 110%;
    display: flex;
    width: 30rem;
    height: 0.3rem;
    background-color: var(--clr-primary-100);
  }
}

.section {
  position: relative;
  padding-block: fluid(7.5rem, 4rem);
}

.dots-bg {
  position: absolute;
  width: 41rem;
  height: 35rem;
  opacity: 0.7;

  &_result {
    right: 0;
    bottom: 10rem;
    transform: rotate(180deg);
  }

  &_why {
    right: 10rem;
    bottom: 50%;
    transform: rotate(180deg);

    path {
      fill: var(--clr-default-100);
    }
  }

  &_services {
    &-1 {
      right: 0;
      top: 10rem;
      transform: rotate(90deg);
    }

    &-2 {
      left: 0;
      bottom: 4rem;
    }
  }

  &_form-example {
    left: 0;
    bottom: 5rem;

    path {
      fill: var(--clr-default-100);
    }
  }

  &_steps {
    &-1 {
      left: 0;
      top: 5rem;
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }

  &_cost {
    &-1 {
      left: 0;
      top: 5rem;
      transform: rotate(180deg);
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }

  &_team {
    &-1 {
      left: 0;
      top: 5rem;
      transform: rotate(180deg);
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }

  &_certificates {
    &-1 {
      left: 5rem;
      bottom: 10rem;
      transform: rotate(-90deg);
    }

    &-2 {
      right: 5rem;
      top: 10rem;
      transform: rotate(90deg);
    }
  }

  &_form {
    right: 50%;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);

    @media (max-width: em(600)) {
      right: -20%;
      top: 80%;
    }

    path {
      fill: var(--clr-default-100);
    }
  }

  &_expertise {
    &-1 {
      left: 0;
      top: 5rem;
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }

  &_contacts {
    &-1 {
      left: 0;
      bottom: 5rem;
    }

    &-2 {
      right: 0;
      top: 10rem;
      transform: rotate(90deg);
    }
  }

  &_victim {
    right: 50%;
    top: 50%;
    transform: translateY(-50%);
  }

  &_team {
    &-1 {
      left: 0;
      top: 5rem;
      transform: rotate(180deg);
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }

  &_help {
    left: 5rem;
    top: 0;
  }

  &_about {
    left: 50%;
    top: 0;
    transform: rotate(180deg);

    path {
      fill: var(--clr-default-100);
    }
  }

  &_caces {
    &-1 {
      left: 0;
      top: 5rem;
      transform: rotate(180deg);
    }

    &-2 {
      right: 0;
      bottom: 5rem;
      transform: rotate(180deg);
    }
  }
}

.iti {
  width: 100%;
}

#phone {
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-bottom-color: var(--clr-primary-100);
  padding-block: 1.6rem 1.4rem;
  width: 100%;
  font-size: 1.6rem;
}
#phone1,
#phone2,
#phone3,
#phone4,
#phone5,
#phone6,
#phone7,
#phone8,
#phone9,
#phone12 {
  border-bottom-style: solid;
  border-bottom-width: 0.2rem;
  border-bottom-color: var(--clr-primary-100);
  padding-block: 1.6rem 1.4rem;
  width: 100%;
  font-size: 1.6rem;
}

.form .iti__selected-dial-code {
  font-size: 1.6rem;
  color: #fff;
}

.iti__selected-dial-code {
  font-weight: 400;
}

#btn {
  display: none;
}

#valid-msg,
#error-msg {
  display: none;
}
