.caces {
  .title-main {
    text-align: center;

    &::after {
      position: absolute;
      left: 50%;
      width: 12rem;
      transform: translate(-50%, -50%);
    }
  }

  &-block {
    display: flex;
    overflow-y: auto;
    margin-top: 5rem;

    &::-webkit-scrollbar {
      width: 0.3rem;
      height: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--clr-primary-100);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--clr-bg-100);
    }

    &__scroll {
      display: flex;
      overflow: hidden;
      margin-bottom: 2rem;
      border: 0.1rem solid var(--clr-default-900);
      width: 100%;
      min-width: 97rem;
    }

    &__item {
      display: flex;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      width: 10rem;
      height: 5rem;

      &:not(:last-child) {
        border-right: 0.1rem solid var(--clr-default-900);
      }

      &.active {
        font-weight: 700;
        color: var(--clr-default-100);
        background-color: var(--clr-primary-100);
      }
    }
  }

  &-content {
    margin-top: 4rem;

    &__item {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      @media (max-width: em(1050)) {
        display: flex;
        flex-direction: column;
      }
    }

    &__text {
      flex-basis: 40%;
      font-size: fluid(2rem, 1.6rem);
      line-height: 150%;
    }

    &__wrap {
      display: flex;
      flex-basis: 55%;
      gap: 1rem;

      @media (max-width: em(1050)) {
        min-height: 50rem;

        @media (max-width: em(600)) {
          flex-direction: column;
          min-height: 100rem;
          gap: 1rem;
        }
      }
    }

    &__img {
      position: relative;
      flex-grow: 1;
      flex-basis: 34%;
      overflow: hidden;

      &_hover {
        &::after {
          content: "";
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0;
          transition: opacity var(--transition);
        }

        &:hover {
          &::after {
            opacity: 0.7;
          }

          .caces-content__img-link {
            opacity: 1;
          }
        }
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        min-height: 100%;
        transform: translate(-50%, -50%);
      }

      &-link {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        display: flex;
        justify-content: center;
        border-style: solid;
        border-width: 0.1rem;
        border-color: var(--clr-default-100);
        border-radius: 3rem;
        padding: 1rem;
        min-width: 22rem;
        font-weight: 700;
        text-transform: uppercase;
        color: var(--clr-default-100);
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: opacity var(--transition);
      }
    }
  }
}
