$overlayBgColor: rgba(0, 0, 0, 0.7);

.modal {
  // position: fixed;
  // left: 0;
  // top: 0;
  // z-index: 20;
  // overflow-x: hidden;
  // overflow-y: auto;
  // width: 100%;
  // height: 100%;
  // background-color: $overlayBgColor;
  // opacity: 0;
  // visibility: hidden;
  // transition: opacity var(--transition), visibility var(--transition);

  // &__wrapper {
  //   @include flex-all-center;

  //   padding: 2.5rem;
  //   min-height: 100%;
  // }

  // &__content {
  //   position: relative;
  //   padding: 2rem;
  //   width: 100%;
  //   max-width: 80rem;
  //   background-color: var(--clr-default-100);
  //   opacity: 0;
  //   transform: translateY(-100%);
  //   transition: transform var(--transition), opacity var(--transition);
  // }

  // &__close {
  //   position: absolute;
  //   right: 2rem;
  //   top: 2rem;
  //   width: 2rem;
  //   height: 2rem;

  //   &::after,
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     left: 50%;
  //     top: 50%;
  //     width: 100%;
  //     height: 0.2rem;
  //     background-color: var(--clr-default-900);
  //   }

  //   &::before {
  //     transform: translate(-50%, -50%) rotate(45deg);
  //   }

  //   &::after {
  //     transform: translate(-50%, -50%) rotate(-45deg);
  //   }
  // }

  // &.show {
  //   opacity: 1;
  //   visibility: visible;
  //   transition: opacity var(--transition), visibility var(--transition);

  //   .modal__content {
  //     opacity: 1;
  //     transform: translateY(0);
  //     transition: transform var(--transition), opacity var(--transition);
  //   }
  // }

  // &-call {
  //   &__content {
  //     padding: 4rem 4.5rem;
  //     width: 100%;
  //     max-width: 47rem;
  //   }

  //   &__caption {
  //     font-weight: 700;
  //     font-size: 2.8rem;
  //     text-align: center;
  //   }

  //   &__text {
  //     margin-top: 2rem;
  //     font-size: 1.8rem;
  //     text-align: center;
  //   }

  //   &__form {
  //     margin-top: 2rem;

  //     form {
  //       display: flex;
  //       flex-direction: column;
  //       gap: 2rem;

  //       input {
  //         border-bottom-style: solid;
  //         border-bottom-width: 0.2rem;
  //         border-bottom-color: var(--clr-primary-100);
  //         padding-block: 1.77rem;
  //         font-size: 1.4rem;
  //       }

  //       button {
  //         border-radius: 3rem;
  //         width: 100%;
  //         font-weight: 700;
  //         font-size: 1.5rem;
  //         text-transform: uppercase;
  //       }
  //     }
  //   }
  // }
}

/* Стили для модального окна */
.modal-call {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: none; /* Изначально скрыто */
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &__content {
    position: relative;
    padding: 4rem 4.5rem;
    width: 100%;
    max-width: 66rem;
    background-color: var(--clr-default-100);
  }

  &__caption {
    font-weight: 700;
    font-size: 2.8rem;
    text-align: center;
  }

  &__text {
    margin-top: 2rem;
    font-size: 1.8rem;
    text-align: center;
  }

  &__form {
    margin-top: 2rem;

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      input {
        border-bottom-style: solid;
        border-bottom-width: 0.2rem;
        border-bottom-color: var(--clr-primary-100);
        padding-block: 1.77rem;
        font-size: 1.4rem;
      }

      button {
        border-radius: 3rem;
        width: 100%;
        font-weight: 700;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
    }
  }
}

/* Кнопка закрытия */
.modal-call__close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 2rem;
  height: 2rem;

  &::after,
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 0.2rem;
    background-color: var(--clr-default-900);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
