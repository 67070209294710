.feedback {
  .swiper {
    margin-top: 3rem;
    width: 100%;
    height: 100%;

    &__prev {
      position: absolute;
      left: 50%;
      top: 12rem;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 5rem;
      height: 5rem;
      background-color: var(--clr-primary-100);
      transform: translateX(-31rem);
      cursor: pointer;

      svg {
        max-width: 1.2rem;
        transform: rotate(180deg);
      }

      @media (max-width: em(650)) {
        left: 0;
        width: 3.5rem;
        height: 3.5rem;
        transform: translateX(0);
      }
    }

    &__next {
      position: absolute;
      left: 50%;
      top: 12rem;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 5rem;
      height: 5rem;
      background-color: var(--clr-primary-100);
      transform: translateX(26rem);
      cursor: pointer;

      svg {
        max-width: 1.2rem;
      }

      @media (max-width: em(650)) {
        left: auto;
        right: 0;
        width: 3.5rem;
        height: 3.5rem;
        transform: translateX(0);
      }
    }

    &-slide {
      opacity: 0.3;
      transition: opacity var(--transition);

      @media (max-width: em(650)) {
        padding: 1rem;
      }

      &__title,
      &__text {
        opacity: 0;
        transition: opacity var(--transition);
      }

      &__title {
        margin-top: 2rem;
        font-size: 1.4rem;
      }

      &-prev {
        .swiper-slide__content {
          margin-inline: auto 0;
        }
      }

      &-next {
        .swiper-slide__content {
          margin-inline: 0 auto;
        }
      }

      &-active {
        opacity: 1;

        .feedback__google,
        .swiper-slide__title,
        .swiper-slide__text {
          opacity: 1;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        margin-inline: auto;
        max-width: 50rem;
        gap: 1rem;
      }

      &__video {
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
      }

      &__text {
        line-height: 150%;
      }

      &__img {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        width: 100%;
        transform: translate(-50%, -50%);

        img {
          width: 100%;
        }
      }

      &__play {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__google {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-inline: auto;
    padding-bottom: 1rem;
    transition: opacity var(--transition);
    gap: 2rem;

    @media (max-width: em(850)) {
      flex-direction: column;
    }

    .btn-primary {
      border-radius: 3rem;
      min-width: max-content;
      box-shadow: 0.3rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.4);
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: none;
    }

    img {
      max-width: 36rem;
    }
  }
}
